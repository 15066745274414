<template>
  <div  class="row no-padding">


    <div class="row">
      <div class="col s12">
        <div class="row col s12 card horizontal vert-offset-top-1">
          <div class="col s12 card-stacked">
            <div class="col s12 card-content">
              <div class="col s12 l10 offset-l1">


                  <div class="col s12">

                    <h4>Activity Log</h4>
                    <div class="col s12">
                      <div class="row">
                        <div class="col s12">
                          <div class="card blue-grey darken-1">
                            <div class="card-content white-text">
                              <span class="card-title">Important</span>
                              <p>Activity log currently in sample mode.</p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                <div class="row">

                  <div class="col s12">
                    <ul class="collection">
                      <li v-for="(_item, _index) in items" :key="_index" class="collection-item avatar">
                        <img :src="_item.image" alt="" class="circle border-1 grey-border">
                        <span class="title">{{_item.name}}</span>
                        <p>
                          {{_item.role}}
                          <br/>
                          {{_item.description}}
                        </p>
                        <div class="secondary-content font-11 grey-text text-darken-2">{{_item.date}}</div>
                      </li>
                    </ul>
                  </div>

                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>














  </div>
</template>

<script>
    // @ is an alias to /src
    export default {
        components: {

        },
        name: 'Home',

        data(){
            return{
                items: [
                    {
                        image: "https://dev.brandboxbw.com/smart_procurement/logo_512x512.png",
                        role: "Business Admin @ SDK Digital Lab",
                        name: "User 20",
                        description: "Updated Total Number of Managers metric @SDK Digital Lab",
                        date: "2022/06/04 @10:30AM"
                    },
                    {
                        image: "https://dev.brandboxbw.com/smart_procurement/logo_512x512.png",
                        role: "Business Admin @ SDK Digital Lab",
                        name: "User 20",
                        description: "Updated Total Number of Female Managers metric @SDK Digital Lab",
                        date: "2022/06/04 @10:30AM"
                    },
                    {
                        image: "https://dev.brandboxbw.com/smart_procurement/logo_512x512.png",
                        role: "System Admin",
                        name: "User 12",
                        description: "Verified Business Entity @SDK Digital Lab",
                        date: "2022/06/03 @11:16AM"
                    },
                    {
                        image: "https://dev.brandboxbw.com/smart_procurement/logo_512x512.png",
                        role: "System Admin",
                        name: "User 12",
                        description: "Verified Local Ownership Percentage @SDK Digital Lab",
                        date: "2022/06/03 @11:16AM"
                    },
                    {
                        image: "https://dev.brandboxbw.com/smart_procurement/logo_512x512.png",
                        role: "Business Admin @ SDK Digital Lab",
                        name: "User 20",
                        description: "Updated Local Ownership Percentage @SDK Digital Lab",
                        date: "2022/06/03 @10:42AM"
                    },
                    {
                        image: "https://dev.brandboxbw.com/smart_procurement/logo_512x512.png",
                        role: "Business Admin @ SDK Digital Lab",
                        name: "User 20",
                        description: "Updated Business Entity @SDK Digital Lab",
                        date: "2022/06/03 @10:35AM"
                    },
                    {
                        image: "https://dev.brandboxbw.com/smart_procurement/logo_512x512.png",
                        role: "Business Admin @ SDK Digital Lab",
                        name: "User 20",
                        description: "Registered a new Supplier SDK Digital Lab",
                        date: "2022/06/03 @10:30AM"
                    }
                ],

            }
        },
    }
</script>
