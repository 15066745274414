<template>
    <div class="row">
        <div class="col s12 m8 l8 offset-m2 offset-l2">

            <div class="card-panel sp-blue vert-offset-top-3 rounded-15">
                <div class="card-content">

                    <div class="row">
                        <div class="col s12 left vert-offset-top-2">
                            <div class="right-align">
                                <button class="btn btn-medium sp-gold vert-offset-left-half vert-offset-right-half">
                                    <router-link to="/" class="sp-blue-text font-16"><i class="material-icons large sp-blue-text">home</i> Home Page</router-link>
                                </button>
                                <button class="btn btn-medium sp-gold vert-offset-left-half vert-offset-right-half">
                                    <router-link to="/suppliers/register" class="sp-blue-text font-16"><i class="material-icons large sp-blue-text">edit</i>Register My Business</router-link>
                                </button>
                                <button class="btn btn-medium sp-gold vert-offset-left-half vert-offset-right-half">
                                    <router-link to="/" class="sp-blue-text font-16"><i class="material-icons large sp-blue-text">search</i>Find Business</router-link>
                                </button>
                            </div>
                        </div>

                        <div class="col s12 valign-wrapper vert-offset-top-4">

                            <div class="col s12 m10 l10 offset-m1 offset-l1">
                                <div class="card">
                                    <div class="card-content">
                                        <form @submit.prevent="login">

                                            <div class="row">

                                                <div class="col s12"><h4>Sign into your account</h4></div>
                                                <div class="col s12 vert-offset-bottom-half">
                                                    <input-component  v-model.trim="$v.email.$model" type="email" id="email" label="E-Mail Address" icon="mail" required="required" :class="{'is-valid': submitted && $v.email.$error,'is-valid': submitted && !$v.email.$invalid}"></input-component>

                                                    <div class="validate col s12 vert-offset-left-3 form-error">
                                                        <div v-if="$v.email.$dirty && !$v.email.required" class="col s12">
                                                            Email is required
                                                        </div>

                                                        <div v-if="$v.email.$dirty && !$v.email.email" class="col s12">
                                                            Your email is invalid
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col s12 vert-offset-bottom-half">
                                                    <input-component  v-model.trim="$v.password.$model" type="password" id="password" label="Password" icon="lock" required="required" :class="{'is-valid': submitted && $v.password.$error, 'is-valid': submitted && !$v.password.$invalid}"></input-component>

                                                    <div class="validate col s12 vert-offset-left-3 form-error">

                                                        <div v-if="$v.password.$dirty && !$v.password.required" class="col s12" >
                                                            Password is required
                                                        </div>
                                                        <div v-if="$v.password.$dirty && !$v.password.minLength" class=" col s12">
                                                            Password should have no less than eight(8) characters.
                                                        </div>
                                                        <div v-if="$v.password.$dirty && !$v.password.maxLength" class="col s12">
                                                            Password too long, your password should have {{$v.password.$params.maxLength.max }} characters or less
                                                        </div>
                                                        <div v-if="submitted && !$v.password.valid" class="col s12">
                                                            Password must contain at least 1 uppercase, 1 lowercase, 1 digit and 1 special symbol (@$!%*#?&)
                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="col s12">
                                                    <div class="col s12 m6 l6 left-align">
                                                        <label for="remember_me">
                                                            <input type="checkbox" name="remember_me" id="remember_me" v-model="remember_me">
                                                            <span>Remember Me</span>
                                                        </label>
                                                    </div>
                                                    <div class="col s12 m6 l6 right-align">
                                                        <router-link to="">
                                                            <span>Forgot Password?</span>
                                                        </router-link>

                                                    </div>
                                                </div>

                                                <div class="col s12">
                                                    <div class="vert-offset-top-2">
                                                        <button type="submit" class="col s12 hoverable btn btn-large sp-gold black-text">
                                                            Login
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </div>
    </div>

</template>
<script>
    import InputComponent from "./materialize/InputComponent";
    import { email, required, minLength, maxLength } from "vuelidate/lib/validators";

    export default {
        name: 'LoginComponent',
        components:{
            InputComponent

        },
        validations: {
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength:minLength(8),
                maxLength:maxLength(100),
                valid:function()
                {
                    return this.validPassword;
                }
            },
        },
        data(){
            return{
                email:null,
                password:null,
                remember_me:false,
                submitted: false,

            }
        },
        created: function()
        {
           /* this.email = "super-admin@doesnotexist.com";
            this.password = "@hello1World";*/

        },
        mounted: function() {

        },
        destroyed:function () {

        },
        methods:{

            login:function(){

                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                const { email, password } = this;
                this.$store.dispatch('AUTH_REQUEST', { email, password }).then((_response) => {

                    if(typeof _response.data !== "undefined" && _response.data !== null)
                    {
                        if(_response.data.error_code === 0)
                        {
                            this.$router.push('/home')
                        }

                    }
                })
            },

        },
        computed: {

            validPassword:function () {
                return this.password.isValidPasswordFormat();
            }
        }
    }
</script>