<template>
    <div class="row padding-top-half">
        <div class="col s12 m10 offset-m1">

            <div class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="col s12 card-title padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2">
                    <span>Registration</span>
                </div>

                <div class="card-content col s12 padding-left-0 padding-right-0 padding-top-1 font-12">

                    <div class="col s12 no-padding">
                        <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">

                            <div class="col s12 padding-bottom-1 padding-top-1">
                                <form @submit.prevent="confirmAccountCreation">

                                    <!--name-->
                                    <div class="col s12 vert-offset-top-1">
                                        <input-component v-model.trim="$v.name.$model" type="text" id="name" label="Business Name" :class="{'is-valid': submitted && $v.name.$error, 'is-valid': submitted && !$v.name.$invalid}"></input-component>
                                        <div class="col s12 form-error">
                                            <span v-if="$v.name.$dirty && !$v.name.required && submitted">
                                                Name is required.
                                            </span>

                                            <span v-if="$v.name.$dirty && !$v.name.minLength">
                                                Name is too short, your business name should be at least {{$v.name.$params.minLength.min }} characters or more.
                                            </span>

                                            <span v-if="$v.name.$dirty && !$v.name.maxLength">
                                                Name is too long, your business name should not be more than {{$v.name.$params.maxLength.max }} characters.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col s12 vert-offset-top-1">
                                        <custom-select-component :options="list_businesstypes"  v-model.trim="$v.selected_businesstype_key.$model" title="Type to select a business type" :searchStart="false" :class="{'is-valid': $v.selected_businesstype_key.$error, 'is-valid': !$v.selected_businesstype_key.$invalid}">
                                        </custom-select-component>
                                        <div class="col s12 form-error">
                                            <span v-if="$v.selected_businesstype_key.$dirty && !$v.selected_businesstype_key.required">Business Type  is required.</span>
                                        </div>
                                    </div>

                                    <!--email, contact number-->
                                    <div class="col s12 vert-offset-top-1 no-padding">
                                        <div class="col s12 m6 vert-offset-bottom-half vert-offset-top-half ">
                                            <input-component  v-model.trim="$v.email.$model" type="email" id="email" label="E-Mail Address" :class="{'is-valid': submitted && $v.email.$error,'is-valid': submitted && !$v.email.$invalid}" :disabled="hasData"></input-component>
                                            <div class="col s12 form-error">
                                                <span v-if="$v.email.$dirty && !$v.email.required">Email is required</span>
                                                <span v-if="$v.email.$dirty && !$v.email.email">Your email is invalid</span>
                                            </div>

                                        </div>
                                        <div class="col s12 m6 vert-offset-bottom-half vert-offset-top-half ">
                                            <input-component v-model.trim="$v.mobile.$model" :class="{'is-valid': $v.mobile.$error,'is-valid': !$v.mobile.$invalid}" type="tel" label="Contact Number">
                                            </input-component>
                                            <div class=" col s12 form-error">
                                                <span v-if="$v.mobile.$dirty && !$v.mobile.required">Contact number is required.</span>
                                                <span  v-if="$v.mobile.$dirty && !$v.mobile.minLength">Contact number should have at least {{ $v.mobile.$params.minLength.min }} digits.</span>
                                                <span v-if="$v.mobile.$dirty && !$v.mobile.maxLength">Contact number should not have more than {{ $v.mobile.$params.maxLength.max }} digits.</span>
                                                <span v-if="$v.mobile.$dirty && !$v.mobile.numeric">Contact number should be {{ $v.mobile.$params.numeric.max }} digits.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--physical address-->
                                    <div class="col s12 vert-offset-top-1">
                                        <input-component
                                                v-model.trim="$v.physical_address.$model"
                                                :class="{
                                             'is-valid': $v.physical_address.$error,
                                            'is-valid': !$v.physical_address.$invalid
                                             }"
                                                type="text"
                                                label="Physical Address"


                                        >
                                        </input-component>
                                        <div class="col s12 form-error">
                                            <span v-if="$v.physical_address.$dirty && !$v.physical_address.maxLength">Physical Address should not have more than {{ $v.physical_address.$params.maxLength.max }} characters.
                                            </span>
                                            <span v-if="$v.physical_address.$dirty && !$v.physical_address.maxLength">Physical Address should have at least {{ $v.physical_address.$params.minLength.min }} characters.
                                            </span>
                                            <span v-if="$v.physical_address.$dirty && !$v.physical_address.required">Physical Address is required.
                                            </span>
                                        </div>
                                    </div>
                                    <!--postal address-->
                                    <div class="col s12 vert-offset-top-1">
                                        <input-component v-model.trim="$v.postal_address.$model" :class="{'is-valid': $v.postal_address.$error, 'is-valid': !$v.postal_address.$invalid}" type="text" label="Postal Address" >
                                        </input-component>
                                        <div class="col s12 form-error">

                                            <span v-if="$v.postal_address.$dirty && !$v.postal_address.required">Postal Address is required.</span>
                                            <span v-if="$v.postal_address.$dirty && !$v.postal_address.maxLength">Postal Address should not have more than {{ $v.postal_address.$params.maxLength.max }} characters.
                                            </span>
                                            <span v-if="$v.postal_address.$dirty && !$v.postal_address.minLength">Postal Address should have at least {{ $v.postal_address.$params.minLength.min}} characters.
                                            </span>
                                        </div>
                                    </div>
                                    <!--Countries, cities, districts-->
                                    <div class="col s12 vert-offset-top-1">
                                        <div class="col s12 m4" v-if="countriesExists">
                                            <div class="row vert-offset-bottom-1 padding-right-half">
                                                <custom-select-component :options="list_countries"  v-model.trim="$v.selected_country_key.$model" title="Type to select the country" :searchStart="false" :class="{'is-valid': $v.selected_country_key.$error, 'is-valid': !$v.selected_country_key.$invalid}">
                                                </custom-select-component>
                                                <div class="col s12 form-error">
                                                    <span v-if="$v.selected_country_key.$dirty && !$v.selected_country_key.required">Country is required.</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col s12 m4" v-if="districtsExists">
                                            <div class="row vert-offset-bottom-1 padding-right-half">
                                                <custom-select-component id="districts" :options="list_districts" v-model.trim="$v.selected_district_key.$model" title="Type to select your District" :searchStart="false"
                                                                         :class="{'is-valid': $v.selected_district_key.$error, 'is-valid': !$v.selected_district_key.$invalid}">
                                                </custom-select-component>
                                                <div class="col s12 form-error">
                                                    <span v-if="$v.selected_district_key.$dirty && !$v.selected_district_key.required">District is required.</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col s12 m4" v-if="citiesExists"
                                        >
                                            <div class="row vert-offset-bottom-1 padding-right-half">
                                                <custom-select-component id="cities" :options="list_cities" v-model="$v.selected_city_key.$model" title="Type to select a city" :searchStart="false" :class="{'is-valid': $v.selected_city_key.$error, 'is-valid': !$v.selected_city_key.$invalid}">
                                                </custom-select-component>
                                                <div class="col s12 form-error">
                                                    <span v-if="$v.selected_city_key.$dirty && !$v.selected_city_key.required">City is required.</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col s12 vert-offset-top-1">
                                        <button type="submit" class="col s12 hoverable btn btn-large sp-gold black-text">
                                            Register
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <!--
                                                                <div class="col s12 no-padding" v-show="step==1">
                                                                    <div class="col s12">
                                                                        <input-component v-model="name" type="text" id="name" label="Business Name" ></input-component>
                                                                    </div>
                                                                    <div class="col s12">
                                                                        <input-component id="description" type="text" v-model="description" label="Please describe your business in a few words." data-length="200"></input-component>
                                                                    </div>
                                                                    <div class="col s12 m6" v-if="existsSectors">
                                                                        <div class="row vert-offset-bottom-1 padding-right-half">
                                                                            <custom-select-component :options="sectors"  v-model="selected_sector" title="Type to select your business sector" :searchStart="false">
                                                                            </custom-select-component>
                                                                        </div>

                                                                    </div>

                                                                    <div class="col s12 m6" v-if="industriesExists">
                                                                        <div class="row vert-offset-bottom-1 padding-right-half">
                                                                            <custom-select-component id="industries" :options="industries" v-show="industriesExists" v-model="selected_industry" title="Type to select your Industry" :searchStart="false">
                                                                            </custom-select-component>
                                                                        </div>

                                                                    </div>

                                                                    <div class="col s12 vert-offset-top-1 no-padding">
                                                                        <div class="col s12 font-12 padding-bottom-half">
                                                                            Please provide <span class="font-bold">five (5) keyword/key-phrases</span> to help customers find you. <span class="font-bold">({{countChips}}/5)</span>
                                                                        </div>
                                                                        <div class="col s12">
                                                                            <custom-chip-component :data="chipsTags" v-on:chipData="chipData" :autocomplete="false">

                                                                            </custom-chip-component>
                                                                            <span class="font-10 blue-text text-darken-4 vert-offset-bottom-1">To confirm a Keyword, press Enter/Return/Go after you are done typing.</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                            -->
                            <!-- <div class="col s12" v-show="step==2">
                                 <div class="col s12 vert-offset-bottom-half">
                                     <h6>Contact Details</h6>
                                 </div>
                                 <div class="col s12 m6">
                                     <input-component id="email" type="email" v-model="email" label="What is your business E-Mail Address?"  autocomplete="email" data-length="150"></input-component>
                                 </div>
                                 <div class="col s12 m6">

                                     <input-component id="phone" type="tel" v-model="phone" label="What is your business Contact Number?" data-length="15"></input-component>
                                 </div>
                             </div>

                             <div class="col s12 vert-offset-bottom-1" v-show="step==3">
                                 <div class="col s12">
                                     <h6>Address</h6>
                                 </div>

                                 <div class="col s12 vert-offset-bottom-half">
                                     <div class="col s12 padding-left-0 padding-right-0 padding-top-1">
                                         <div class="col s12">
                                             <custom-select-component :options="districts" v-model="selected_district_key" v-if="districtsExists" title="Type to select your District">

                                             </custom-select-component>
                                         </div>

                                     </div>
                                     <div class="col s12 padding-left-0 padding-right-0 padding-top-1" v-show="citiesExists">
                                         <div class="col s12">
                                             <custom-select-component :options="cities" v-model="selected_city_key"  title="Type to select your City">
                                             </custom-select-component>
                                         </div>
                                     </div>
                                     <div class="col s12 padding-left-0 padding-right-0 padding-top-1" v-show="wardsExists">
                                         <div class="col s12">
                                             <custom-select-component :options="wards" v-model="selected_ward_key"  title="Type to select your Location">

                                             </custom-select-component>
                                         </div>

                                     </div>
                                 </div>
                                 <div class="col s12 padding-top-1">
                                     <input-component  id="physical_address" type="text" v-model="physical_address" label="Physical Address" data-length="200"></input-component>
                                 </div>
                                 &lt;!&ndash;<div class="col s12 m6 padding-top-1">
                                     <input-component id="postal_address" type="text" v-model="postal_address" label="Postal Address" data-length="200" helper="Optional"></input-component>
                                 </div>&ndash;&gt;
                                 <div class="col s12 no-padding vert-offset-top-half">
                                     <div class="col s12 vert-offset-bottom-half">
                                         <h6>GeoCoordinates</h6>
                                     </div>
                                     <div class="col s12 m6 l4">
                                         <input-component  id="supplier_latitude" type="text" v-model="supplier_latitude" label="Latitude" helper="Optional" autocomplete="off" ref="supplier_latitude"></input-component>
                                     </div>
                                     <div class="col s12 m6 l4">
                                         <input-component  id="supplier_longitude" type="text" v-model="supplier_longitude" label="Longitude" autocomplete="off" helper="Optional" ref="supplier_longitude"></input-component>
                                     </div>
                                     <div class="col s12 l4 center-align">
                                         <button v-show="canGeoLocate && geoSearch"
                                                 @click.prevent="locateMe"
                                                 class="btn btn-tiny font-10  sp-gold text-darken-2 vert-offset-left-half"
                                         >
                                             <i class="material-icons tiny left ">my_location</i>
                                             Get Current Location
                                         </button>
                                     </div>
                                 </div>
                             </div>

                             <div class="col s12" v-show="step == 4">
                                 <div class="col s12 m6">
                                     <div class="col s12 no-padding grey-border border-lighten-2 border-bottom-1 vert-offset-bottom-1 vert-offset-top-half">
                                         <div class="col s12 no-padding">

                                             <div class="col s12 no-padding vert-offset-top-1">
                                                 Do you have a office location?
                                             </div>

                                             <div class="col s12 m6 vert-offset-bottom-1 input-field">
                                                 <p>
                                                     <label @click="yesCreateSite">
                                                         <input type="radio" name="own-location"/>
                                                         <span class="bold sp-gold-text">Yes</span>
                                                     </label>
                                                 </p>
                                             </div>

                                             <div class="col s12 m6 vert-offset-bottom-1 input-field">
                                                 <p>
                                                     <label @click="noCreateSite">
                                                         <input type="radio" name="own-location"/>
                                                         <span class="bold sp-gold-text">No</span>
                                                     </label>
                                                 </p>
                                             </div>

                                         </div>
                                     </div>
                                 </div>

                                 <div class="col s12 m6">
                                     <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-top-half vert-offset-bottom-half">
                                         <div class="col s12 border-bottom-1 grey-border border-lighten-2 padding-bottom-half">
                                             <span>How much discount will you like to give? (%)</span>
                                         </div>
                                         <div class="col s12 vert-offset-bottom-1 input-field">
                                             <p v-for="(item, index) in discounts"
                                                :key="index">
                                                 <label>
                                                     <input type="radio" :value="item"
                                                            :key="index" v-model="discount"/>
                                                     <span v-html="item" class="bold sp-gold-text"></span>
                                                 </label>
                                             </p>


                                         </div>
                                     </div>
                                 </div>
                             </div>


                             <div class="col s12" v-show="step ==5">
                                 <img width="200" height="200" :src="picture" v-if="isPicture" ref="logoImage"/>
                                 <div class="file-field input-field">


                                     <div class="btn yellow darken-2 black-text">
                                         <span>Logo</span>
                                         <input type="file"  accept="image/*" @change="uploadImage"/>
                                     </div>
                                     <div class="file-path-wrapper">
                                         <input class="file-path " type="text"/>
                                     </div>
                                 </div>
                             </div>

                             <div class="col s12" v-show="step ==6">
                                 <h5>Please confirm your information below</h5>

                                 <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                     <div class="col s12">
                                         <h6>Business Details</h6>
                                     </div>
                                     <div class="col s12 vert-offset-top-half">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Name
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{name}}
                                         </div>
                                     </div>
                                     <div class="col s12 vert-offset-top-half" v-if="description != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Description
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{description}}
                                         </div>
                                     </div>


                                     <div class="col s12 vert-offset-top-half" v-if="selected_sector_object != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Organisation Sector
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{selected_sector_object.name}}
                                         </div>
                                     </div>

                                     <div class="col s12 vert-offset-top-half" v-if="selected_industry_object != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Industry
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{selected_industry_object.name}}
                                         </div>
                                     </div>



                                     <div class="col s12 vert-offset-top-half ">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Tags
                                         </div>

                                         <div class="col s8 m9 l10 font-10">
                                 <span
                                         v-for="(_item,_index) in selectedChips"
                                         :key="_index"
                                         class="vert-offset-right-half underline lighten-2 grey-text text-darken-2"
                                 >
                                     {{ _item }}
                                 </span>
                                         </div>
                                     </div>
                                 </div>


                                 <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                     <div class="col s12">
                                         <h6>Contact Details</h6>
                                     </div>
                                     <div class="col s12 vert-offset-top-half">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Email
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{email}}
                                         </div>
                                     </div>
                                     <div class="col s12 vert-offset-top-half">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Contact Number
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{phone}}
                                         </div>
                                     </div>
                                 </div>

                                 <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                     <div class="col s12">
                                         <h6>Location Details</h6>
                                     </div>

                                     <div class="col s12 vert-offset-top-half" v-if="selected_district_object != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             District
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{selected_district_object.name}}
                                         </div>
                                     </div>

                                     <div class="col s12 vert-offset-top-half" v-if="selected_city != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             City
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{selected_city.name}}
                                         </div>
                                     </div>

                                     <div class="col s12 vert-offset-top-half" v-if="selected_ward != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Ward
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{selected_ward.name}}
                                         </div>
                                     </div>

                                     <div class="col s12 vert-offset-top-half" v-if="physical_address != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Physical Address
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{physical_address}}
                                         </div>
                                     </div>
                                     &lt;!&ndash;<div class="col s12 vert-offset-top-half" v-if="postal_address != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Postal Address
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{postal_address}}
                                         </div>
                                     </div>&ndash;&gt;

                                     <div class="col s12 vert-offset-top-half " v-if="supplier_latitude != null && supplier_longitude != null">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             GPS Coordinates
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{supplier_latitude}},{{supplier_longitude}}
                                         </div>
                                     </div>

                                 </div>

                                 <div class="col s12 border-bottom-1 grey-border vert-offset-top-half padding-bottom-half">
                                     <div class="col s12">
                                         <h6>Other Information</h6>
                                     </div>
                                     <div class="col s12 vert-offset-top-half">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Discount
                                         </div>
                                         <div class="col s8 m9 l10 black-text">
                                             {{discount}}%
                                         </div>
                                     </div>

                                     <div class="col s12 vert-offset-top-half">
                                         <div class="col s4 m3 l2 grey-text text-darken-2">
                                             Do you have an office location?
                                         </div>
                                         <div class="col s8 m9 l10 black-text" v-if="showSiteRegistrationForm">
                                             Yes
                                         </div>
                                         <div class="col s8 m9 l10 black-text" v-else>
                                             No
                                         </div>
                                     </div>

                                 </div>

                                 <div class="col s12 vert-offset-top-half padding-bottom-half">
                                     <div class="col s12">
                                         <h6>Logo</h6>
                                     </div>
                                     <div class="col s12 vert-offset-top-half" v-if="isPicture">
                                         <img width="100" height="100" :src="picture" />
                                     </div>
                                 </div>


                             </div>
        -->
                        </div>

                        <!-- <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
                             <buttons-component v-on:validOrganisation="confirmCreateOrganisation" :buttonDisabled="showNextButton" :maxStep="maxStep" :step="step" :useSteps="true" ></buttons-component>
                         </div>-->
                    </div>

                </div>
            </div>
        </div>

    </div>

</template>
<script>
    import M from 'materialize-css'
    /*import TabComponent from "@/components/materialize/TabComponent.vue";
     import CollapsibleComponent from "@/components/materialize/CollapsibleComponent.vue";*/
    import {email, maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
    import InputComponent from "@/components/materialize/InputComponent.vue";
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue";
    import {mapState} from "vuex";
    import { bus } from "../../main";

    export default{
        name:"RegisterSupplier",
        components:{
            InputComponent,CustomSelectComponent
        },
        props:{
            data:{
                type:Array,
            }
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(100)
            },
            mobile: {
                required,
                numeric,
                minLength: minLength(7),
                maxLength: maxLength(12)
            },
            email: {
                email,
                required,
                maxLength: maxLength(100)
            },
            postal_address: {
                required,
                minLength:minLength(3),
                maxLength: maxLength(200)
            },
            physical_address: {
                required,
                maxLength: maxLength(200),
                minLength: minLength(3)
            },
            selected_city_key:{
                required
            },
            selected_country_key:{
                required
            },
            selected_district_key:{
                required
            },
            selected_businesstype_key: {
                required,
            },
        },

        created(){
            M.AutoInit();


            this.getCountries();
            this.getBusinessTypes();

            let _thisObj = this;
            bus.$off('confirmAction');
            bus.$on("confirmAction", _data => {
                if (_data == "confirmAccountCreation")
                {
                    console.log("ACTION: " + _data);
                    _thisObj.register();
                }
                _thisObj.$store.dispatch("SET_ACTION", "");
            });
        },
        mounted(){


        },
        data(){
            return {
                mobile:null,
                name:null,
                email:null,
                physical_address:null,
                postal_address:null,
                selected_city:null,
                selected_city_key:null,
                selected_district:null,
                selected_district_key:null,
                selected_country:null,
                selected_country_key:null,
                selected_businesstype_key: null,
                submitted:false

            }
        },
        methods:{

            confirmAccountCreation: function() {
                let _objThis = this;
                _objThis.submitted = true;
                _objThis.$v.$touch();
                if (_objThis.$v.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmAccountCreation");
                _objThis.$store.dispatch("SET_ACTION", "confirmAccountCreation");
            },
            register:function(){

                let _objThis = this;

                let _formdata = {
                    name:_objThis.name,
                    email:_objThis.email,
                    contact_number:_objThis.mobile,
                    physical_address:_objThis.physical_address,
                    postal_address:_objThis.postal_address,
                    type:_objThis.selected_businesstype_key,
                    country:_objThis.selected_country.key,
                    district:_objThis.selected_district.key,
                    city:_objThis.selected_city.key
                };
                this.$store.dispatch('SUPPLIERS_SAVE',_formdata).then(function () {
                    this.$router.push('/home');
                });

                window.console.log("Form Data: "+_formdata);
            },
            getCountries: function() {
                this.$store.dispatch('GET_COUNTRIES_LIST');
            },
            getBusinessTypes: function() {
                this.$store.dispatch('GET_BUSINESSTYPES_LIST');
            },
            getCities:function (_district) {
                let _formdata = {district:_district};
                this.$store.dispatch('GET_CITIES_LIST',_formdata);

            },
            getDistricts:function (_country) {

                let _formdata = {country:_country};
                this.$store.dispatch('GET_DISTRICTS_LIST',_formdata);
            },
            getWards:function (_city) {
                let _formdata = {city:_city};
                this.$store.dispatch('GET_COUNTRIES_LIST',_formdata);
            },
        },
        computed:{
                    ...mapState({
                list_countries: state => state.organisations.list_countries,
                list_districts:state => state.list_districts,
                list_cities:state => state.list_cities,
                list_businesstypes:state => state.organisations.list_businesstypes,
            }),

            hasData:function () {
                return typeof this.data !== "undefined" && this.data !== null && this.data.length > 0;
            },
            citiesExists: function() {
                return typeof this.list_cities !== "undefined" && this.list_cities !== null && this.list_cities.length > 0;
            },

            countriesExists:function () {
                return typeof this.list_countries !== "undefined" && this.list_countries !== null && this.list_countries.length > 0;
            },

            districtsExists:function () {
                return typeof this.list_districts !== "undefined" && this.list_districts !== null && this.list_districts.length > 0;
            },
        },
        watch:{
            "selected_country_key": function (_value) {

                this.$store.dispatch("SET_DISTRICTS_LIST",null);
                window.console.log("Selected Country: "+_value);
                this.selected_country = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_countries.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        this.getDistricts(_value);
                        console.log("Found Country: "+_arrFound[0].id);
                        this.selected_country = _arrFound[0];

                    }
                }
            },
            "selected_district_key": function (_value) {
                this.$store.dispatch("SET_CITIES_LIST_ACTION",null);

                this.selected_district = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_districts.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        this.getCities(_value);
                        console.log("Found District: "+_arrFound[0].id);
                        this.selected_district = _arrFound[0];

                    }
                }
            },
            "selected_city_key": function (_value) {
                //this.list_cities =[];

                this.selected_city = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_cities.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        //this.getWards(_value);
                        console.log("Found City: "+_arrFound[0].id);
                        this.selected_city = _arrFound[0];

                    }
                }
            }


        }



    }
</script>