<template>
  <div  class="row no-padding">
    <!--<div class="col s12 font-bold font-16 vert-offset-top-1 padding-bottom-1 grey-border border-bottom-1 border-lighten-2">
      &lt;!&ndash;<tab-component>&ndash;&gt;
        &lt;!&ndash;<li class="tab col s4"><a class="active" href="#supplierslist">All suppliers</a></li>&ndash;&gt;
        &lt;!&ndash;<li class="tab col s4"><a href="#blocked_suppliers">Blocked suppliers</a></li>&ndash;&gt;
        &lt;!&ndash;<li class="tab col s4"><a href="#none_transacting">None Transacting suppliers</a></li>&ndash;&gt;
      &lt;!&ndash;</tab-component>&ndash;&gt;

    </div>-->

    <div class="row">
      <div class="col s12">
        <div class="row col s12 card horizontal vert-offset-top-1">
          <div class="col s12 card-stacked">
            <div class="col s12 card-content">
              <div class="col s12 l10 offset-l1" id="supplierslist">


                <div class="col s12 padding-top-1">

                  <div class="col s12 no-padding vert-offset-bottom-1 border-bottom-1 grey-border border-lighten-2">
                    <div class="col s12 ">
                      <div class="switch">
                        <label>
                          Search By Supplier Name
                          <input  type="checkbox" v-model.trim="search_option">
                          <span class="lever"></span>
                          Search By Product
                        </label>
                      </div>
                    </div>
                    <div class="col s12" v-if="!search_option">
                      <div class="col s12 m9 vert-offset-top-half">
                        <input-component label="Search Suppliers" id="supplier_search_field" v-model="search_field" type="text" icon="search" ></input-component>
                      </div>
                      <div class="col s12 m3 vert-offset-top-half right-align"><button class="waves-effect waves-light btn x-small sp-gold black-text"  @click.prevent="searchSupplier()"><i class="material-icons small black-text">search</i>Find</button>
                      </div>
                    </div>
                    <div class="col s12" v-if="search_option">
                      <div class="col s12 m9" v-if="productsExists">
                        <div class="row vert-offset-bottom-1 padding-right-half">
                          <custom-select-component id="products" :options="list_products" v-model.trim="$v.selected_product_key.$model" title="Type to select a product/service" :searchStart="false"
                                                   :class="{'is-valid': $v.selected_product_key.$error, 'is-valid': !$v.selected_product_key.$invalid}">
                          </custom-select-component>
                          <div class="col s12 form-error">
                            <span v-if="$v.selected_product_key.$dirty && !$v.selected_product_key.required">Product/Service is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col s12 m3 vert-offset-top-half right-align"><button class="waves-effect waves-light btn x-small sp-gold black-text"  @click.prevent="searchProduct()"><i class="material-icons small black-text">search</i>Find</button>
                      </div>
                    </div>

                    <div class="col s12 no-padding">
                      <div class="col s12">
                        <a @click.prevent="clearSearchInput()" v-if="hasSearchInput" class="underline grey-text text-darken-2 font-10 clickable margin-right-1">Clear Search</a>
                        <a @click.prevent="showFilters()" v-if="!displayFilters" class="underline grey-text text-darken-2 font-10 clickable margin-right-1">Show Filters</a>
                        <a @click.prevent="hideFilters()" v-if="displayFilters" class="underline grey-text text-darken-2 font-10 clickable">Hide Filters</a>
                      </div>
                      <div class="col s12 card padding-top-1 padding-bottom-1" v-if="displayFilters">



                        <div class="col s12 m4" v-if="districtsExists">
                          <div class="row vert-offset-bottom-1 padding-right-half">
                            <custom-select-component id="districts" :options="list_districts" v-model.trim="$v.selected_district_key.$model" title="Type to select your District" :searchStart="false"
                                                     :class="{'is-valid': $v.selected_district_key.$error, 'is-valid': !$v.selected_district_key.$invalid}">
                            </custom-select-component>
                            <div class="col s12 form-error">
                              <span v-if="$v.selected_district_key.$dirty && !$v.selected_district_key.required">District is required.</span>
                            </div>
                          </div>

                        </div>

                        <div class="col s12 m4" v-if="citiesExists">
                          <div class="row vert-offset-bottom-1 padding-right-half">
                            <custom-select-component id="cities" :options="list_cities" v-model="$v.selected_city_key.$model" title="Type to select a city" :searchStart="false" :class="{'is-valid': $v.selected_city_key.$error, 'is-valid': !$v.selected_city_key.$invalid}">
                            </custom-select-component>
                            <div class="col s12 form-error">
                              <span v-if="$v.selected_city_key.$dirty && !$v.selected_city_key.required">City is required.</span>
                            </div>
                          </div>

                        </div>
                        
                        <div class="col s12 vert-offset-top-half vert-offset-bottom-half">
                          <div class="col s12 m6" v-if="entitiesExists">
                            <div class="row vert-offset-bottom-1 padding-right-half">
                              <custom-select-component :options="list_entities" v-model.trim="$v.selected_entity_key.$model" title="Type to select your Entity" :searchStart="false"
                                                       :class="{'is-valid': $v.selected_entity_key.$error, 'is-valid': !$v.selected_entity_key.$invalid}">
                              </custom-select-component>
                              <div class="col s12 form-error">
                                <span v-if="$v.selected_entity_key.$dirty && !$v.selected_entity_key.required">Entity is required.</span>
                              </div>
                            </div>

                          </div>
                          <div class="col s12 m6" v-if="ownershipsExists">
                            <div class="row vert-offset-bottom-1 padding-right-half">
                              <custom-select-component :options="list_ownerships" v-model.trim="$v.selected_ownership_key.$model" title="Type to select your Ownership" :searchStart="false"
                                                       :class="{'is-valid': $v.selected_ownership_key.$error, 'is-valid': !$v.selected_ownership_key.$invalid}">
                              </custom-select-component>
                              <div class="col s12 form-error">
                                <span v-if="$v.selected_ownership_key.$dirty && !$v.selected_ownership_key.required">Ownership is required.</span>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="col s12 vert-offset-top-half vert-offset-bottom-half">
                          <div class="col s12 m6" v-if="industriesExists">
                            <div class="row vert-offset-bottom-1 padding-right-half">
                              <custom-select-component :options="list_industries" v-model.trim="$v.selected_industry_key.$model" title="Type to select your Industry" :searchStart="false"
                                                       :class="{'is-valid': $v.selected_industry_key.$error, 'is-valid': !$v.selected_industry_key.$invalid}">
                              </custom-select-component>
                              <div class="col s12 form-error">
                                <span v-if="$v.selected_industry_key.$dirty && !$v.selected_industry_key.required">Industry is required.</span>
                              </div>
                            </div>

                          </div>
                          <div class="col s12 m6" v-if="sectorsExists">
                            <div class="row vert-offset-bottom-1 padding-right-half">
                              <custom-select-component :options="list_sectors" v-model.trim="$v.selected_sector_key.$model" title="Type to select your Sector" :searchStart="false"
                                                       :class="{'is-valid': $v.selected_sector_key.$error, 'is-valid': !$v.selected_sector_key.$invalid}">
                              </custom-select-component>
                              <div class="col s12 form-error">
                                <span v-if="$v.selected_sector_key.$dirty && !$v.selected_sector_key.required">Sector is required.</span>
                              </div>
                            </div>

                          </div>
                        </div>



                        <div class="col s12 right-align">
                          <button @click.prevent="clearFilters" class="btn btn-tiny grey darken-2 black-text margin-right-1">Clear Filters</button>
                          <button @click.prevent="saveFilters" class="btn btn-tiny sp-gold black-text">Save Filters</button>
                        </div>
                      </div>
                    </div>


                  </div>


                  <div class="col s12">
                    <h4>Suppliers</h4>
                  </div>

                  <div v-if="hasSuppliers" class="col s12">
                    <div class="col s12 padding-bottom-half grey-border border-bottom-1 border-lighten-2 right-align" v-if="hasPagination && !searched">
                      <span class="font-10 grey-text">Showing {{pagination.count}} of {{pagination.total}}</span>
                    </div>


                    <table class="font-10" v-if="hasPagination && !searched">
                      <thead>
                      <tr>
                        <th colspan="1"></th>
                        <th colspan="1">Verified</th>
                        <th colspan="4">Name</th>
                        <th colspan="2">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(_item, _index) in suppliers" :key="_index">
                        <td colspan="1">{{ _index + 1}}</td>
                        <td colspan="1">

                          <i class="material-icons small green-text text-darken-2" v-if="_item.verified">check_circle</i>
                          <i class="material-icons small red-text text-darken-2" v-if="!_item.verified">cancel</i>

                        </td>
                        <td colspan="4">
                          <i class="material-icons tiny green-text darken-4" v-if="_item.active">lens</i>
                          <i class="material-icons tiny red-text darken-4" v-if="!_item.active">lens</i>
                          <span class="font-14">{{ _item.name}}</span>
                        </td>
                        <td colspan="2"><button
                                class="btn btn-tiny sp-gold black-text"
                                @click.prevent="getSupplier(_item)"
                        >view</button></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col s12 no-padding" v-else>
                    <div class="row">
                      <div class="col s12">
                        <h5>No Supplier profiles found.</h5>
                      </div>
                    </div>

                  </div>

                </div>
                <div
                        class="col s12 vert-offset-top-half padding-top-1 grey-border border-bottom-1 border-lighten-2 center" v-if="hasSuppliers && !searched">
                  <button
                          v-if="showNext && downloaded"
                          class="btn btn-tiny homeboy-gold-text black"
                          @click.prevent="getSuppliers()"
                  >More</button>

                  <button
                          v-if="downloaded && hasSuppliers && !showNext"
                          class="btn btn-tiny homeboy-gold-texts black"
                          @click.prevent="resetListSuppliers()"
                  >Refresh</button>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>














  </div>
</template>

<script>
    // @ is an alias to /src
    import InputComponent from "@/components/materialize/InputComponent.vue";
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue";
    import {/*maxLength, minLength, numeric,*/ required} from "vuelidate/lib/validators";
    import {mapState} from "vuex";

    export default {
        name: 'Home',
        components: {
            InputComponent,CustomSelectComponent
        },
        data(){
            return{
                downloaded: false,
                pagination: null,
                links: null,
                search_field:"",
                searched:false,
                supplier:null,
                displayFilters: false,
                search_option:false,
                selected_city:null,
                selected_city_key:null,
                selected_district:null,
                selected_district_key:null,
                selected_country:"BW",
                selected_country_key:null,
                selected_businesstype_key: null,
                selected_ownership:null,
                selected_ownership_key:null,
                selected_entity:null,
                selected_entity_key:null,
                selected_industry:null,
                selected_industry_key:null,
                selected_sector:null,
                selected_sector_key:null,
                selected_product_key:null,
                selected_product:null,


            }
        },
        validations: {
            /*name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(100)
            },
            mobile: {
                required,
                numeric,
                minLength: minLength(7),
                maxLength: maxLength(12)
            },
            email: {
                email,
                required,
                maxLength: maxLength(100)
            },
            postal_address: {
                required,
                minLength:minLength(3),
                maxLength: maxLength(200)
            },
            physical_address: {
                required,
                maxLength: maxLength(200),
                minLength: minLength(3)
            },

            selected_country_key:{
                required
            },*/
            selected_product_key:{
                required
            },
            selected_city_key:{
                required
            },
            selected_district_key:{
                required
            },
            selected_ownership_key:{
                required
            },
            selected_entity_key:{
                required
            },
            selected_industry_key:{
                required
            },
            selected_sector_key:{
                required
            },
            /*selected_businesstype_key: {
                required,
            },*/
        },
        created: function()
        {
            this.getSuppliers();

        },
        mounted: function() {
          this.getDistricts("BW");
          this.getOwnerships();
          this.getEntities();
            this.getIndustries();
            this.getSectors();
            this.getProducts();
        },
        destroyed:function () {

        },
        methods:{

            clearSearchInput()
            {
                this.search_field = null;
                this.getSuppliers();
            },
            saveFilters()
            {
                this.hideFilters();
                this.getSuppliers();
            },
            clearFilters()
            {
                this.selected_district_key = null;
                this.selected_city_key = null;
                this.saveFilters();
            },
            showFilters()
            {
                this.displayFilters = true;
            },
            hideFilters()
            {
                this.displayFilters = false;
            },
            scroll ()
            {
                let _thisObj = this;
                window.onscroll = () =>
                {
                  /*window.console.log("ScrollTop: "+ document.documentElement.scrollTop);*/
                    let _refresh = null;
                    if((document.documentElement.scrollTop === 0) && (_thisObj.scrollPoint > 0))
                    {
                        if(_thisObj.new_content)
                        {
                            _refresh = true;
                        }
                    }
                    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                    if ((bottomOfWindow && _thisObj.showNext)||_refresh === true)
                    {
                        _thisObj.getSuppliers(_refresh);
                    }
                    _thisObj.scrollPoint = document.documentElement.scrollTop;
                };
            },
            resetListSuppliers: function()
            {
                window.scrollTo(0, 0);
                this.pagination = null;
                this.links = null;
                this.getSuppliers({});
            },
            searchSupplier:function(){

                if(this.hasSearchInput)
                {
                    this.getSuppliers();
                }

                /*let _thisObj = this;
                let _formData = {supplier:_thisObj.supplier_number_field};
                _thisObj.$store.dispatch('FC_SEARCH_ACCOUNT',_formData)
                    .then(function(_response)
                    {
                        _thisObj.searched = true;

                        if(typeof _response.data.data !== "undefined" && _response.data.data !== null)
                        {
                            _thisObj.supplier =  _response.data.data;
                        }

                    });*/
            },

            searchProduct:function(){

                if(this.hasSearchProduct)
                {
                    this.getSuppliers();
                }

              /*let _thisObj = this;
               let _formData = {supplier:_thisObj.supplier_number_field};
               _thisObj.$store.dispatch('FC_SEARCH_ACCOUNT',_formData)
               .then(function(_response)
               {
               _thisObj.searched = true;

               if(typeof _response.data.data !== "undefined" && _response.data.data !== null)
               {
               _thisObj.supplier =  _response.data.data;
               }

               });*/
            },

            getSuppliers:function ()
            {
                let _thisObj = this;
                let _formData = {};

                if(typeof _formData === "undefined" || _formData === null)
                {
                    _formData = {
                        page: _thisObj.next_page,
                        role: this.userRole.key,
                        district: _thisObj.selected_district_key,
                        city: _thisObj.selected_city_key,
                        ownerships: ((_thisObj.hasSelectedOwnership)?[_thisObj.selected_ownership_key]:null),
                        entities: ((_thisObj.hasSelectedEntity)?[_thisObj.selected_entity_key]:null),
                        sectors: ((_thisObj.hasSelectedSector)?_thisObj.selected_sector_key:null),
                        keyword: ((_thisObj.hasSearchInput)?_thisObj.search_field.trim():null),
                        products: ((_thisObj.hasSearchProduct)?[_thisObj.selected_product_key.trim()]:null),
                    };
                }
                else
                {
                    _formData = {
                        role: this.userRole.key,
                        district: _thisObj.selected_district_key,
                        city: _thisObj.selected_city_key,
                        ownerships: ((_thisObj.hasSelectedOwnership)?[_thisObj.selected_ownership_key]:null),
                        entities: ((_thisObj.hasSelectedEntity)?[_thisObj.selected_entity_key]:null),
                        sectors: ((_thisObj.hasSelectedSector)?_thisObj.selected_sector_key:null),
                        industry: ((_thisObj.hasSelectedIndustry)?_thisObj.selected_industry_key:null),
                        keyword: ((_thisObj.hasSearchInput)?_thisObj.search_field.trim():null),
                        products: ((_thisObj.hasSearchProduct)?[_thisObj.selected_product_key.trim()]:null),

                    }
                }
                console.log("Role: "+_formData);
                this.$store.dispatch('GET_SUPPLIERS',_formData).then(function (_response) {
                    _thisObj.downloaded = true;

                    if(typeof _response.data.pagination !== "undefined" && _response.data.pagination !== null)
                    {
                        _thisObj.pagination = _response.data.pagination;
                        window.console.log("Pagination: "+_response.data.pagination);

                    }
                    if(typeof _response.data.links !== "undefined" && _response.data.links !== null)
                    {
                        _thisObj.links = _response.data.links;
                    }

                    //window.console.log("Extra: "+JSON.stringify(_response.data.extra));
                  /* if(typeof _response.data.extra !== "undefined" && _response.data.extra !== null && _response.data.extra.length > 0 )
                   {
                   //_thisObj.customers = _response.data.extra;
                   }
                   */
                });
            },
            getSupplier(_item){
                let _thisObj = this;
                if(typeof _item === "undefined" || _item === null)
                {
                    return;
                }
                else
                {
                    let _formData = {item:_item.key,type:_item.type.data.key};
                    _thisObj.$store.dispatch('GET_SUPPLIER',_formData)
                        .then(function(_response)
                        {
                            if(typeof _response.data !== "undefined" && _response.data !== null)
                            {
                                if(_response.data.error_code === 0)
                                {
                                    _thisObj.$router.push("/suppliers/view");
                                }

                            }


                        });
                }


            },


            getBusinessTypes: function() {
                this.$store.dispatch('GET_BUSINESSTYPES_LIST');
            },
            getProducts: function() {
                this.$store.dispatch('GET_PRODUCTS_LIST');
            },
            getCities:function (_district) {
                this.$store.dispatch("SET_CITIES_LIST_ACTION",null);

                let _formdata = {district:_district};
                this.$store.dispatch('GET_CITIES_LIST',_formdata);

            },
            getDistricts:function (_country) {

                let _formdata = {country:_country};
                this.$store.dispatch('GET_DISTRICTS_LIST',_formdata);
            },
            getWards:function (_city) {
                let _formdata = {city:_city};
                this.$store.dispatch('GET_COUNTRIES_LIST',_formdata);
            },
            getOwnerships:function () {
                this.$store.dispatch('GET_OWNERSHIPTYPES_LIST');
            },
            getEntities:function () {
                this.$store.dispatch('GET_PATRONAGETYPES_LIST');
            },
            getIndustries:function () {
                this.$store.dispatch('GET_INDUSTRIES_LIST');
            },
            getSectors:function () {
                this.$store.dispatch('GET_SECTORS_LIST');
            },

        },
        computed: {
            ...mapState({
                user: state => state.user,
                suppliers:state=> state.organisations.paginated_list_suppliers,
                list_countries: state => state.countries.list_countries,
                list_districts:state => state.districts.list_districts,
                list_cities:state => state.list_cities,
                list_businesstypes:state => state.organisations.list_businesstypes,
                list_ownerships:state => state.organisations.list_ownershiptypes,
                list_entities:state => state.organisations.list_patronagetypes,
                list_industries:state => state.organisations.list_industries,
                list_sectors:state => state.organisations.list_sectors,
                list_products:state => state.organisations.list_products,
            }),



            citiesExists: function() {
                return typeof this.list_cities !== "undefined" && this.list_cities !== null && this.list_cities.length > 0;
            },

            countriesExists:function () {
                return typeof this.list_countries !== "undefined" && this.list_countries !== null && this.list_countries.length > 0;
            },

            districtsExists:function () {
                return typeof this.list_districts !== "undefined" && this.list_districts !== null && this.list_districts.length > 0;
            },
            productsExists:function () {
                return typeof this.list_products !== "undefined" && this.list_products !== null && this.list_products.length > 0;
            },
            ownershipsExists:function () {
                return typeof this.list_ownerships !== "undefined" && this.list_ownerships !== null && this.list_ownerships.length > 0;
            },
            entitiesExists:function () {
                return typeof this.list_entities !== "undefined" && this.list_entities !== null && this.list_entities.length > 0;
            },
          hasSelectedOwnership: function()
          {
              return (this.ownershipsExists && typeof this.selected_ownership_key !== "undefined" && this.selected_ownership_key !== null && this.selected_ownership_key.length > 0);
          },
            hasSelectedEntity: function()
            {
                return (this.entitiesExists && typeof this.selected_entity_key !== "undefined" && this.selected_entity_key !== null && this.selected_entity_key.length > 0);
            },

            sectorsExists:function () {
                return typeof this.list_sectors !== "undefined" && this.list_sectors !== null && this.list_sectors.length > 0;
            },
            industriesExists:function () {
                return typeof this.list_industries !== "undefined" && this.list_industries !== null && this.list_industries.length > 0;
            },
            hasSelectedSector: function()
            {
                return (this.sectorsExists && typeof this.selected_sector_key !== "undefined" && this.selected_sector_key !== null && this.selected_sector_key.length > 0);
            },
            hasSelectedIndustry: function()
            {
                return (this.industriesExists && typeof this.selected_industry_key !== "undefined" && this.selected_industry_key !== null && this.selected_industry_key.length > 0);
            },
            hasSearchInput: function()
            {
                return (typeof this.search_field !== "undefined" && this.search_field !== null && this.search_field.trim().length > 0);
            },
            hasSearchProduct: function()
            {
                return (this.sectorsExists && typeof this.selected_product_key !== "undefined" && this.selected_product_key !== null && this.selected_product_key.length > 0);
            },

            hasPagination:function () {
                return typeof this.pagination !== "undefined" && this.pagination !== null;
            },

            hasUser:function () {
                return typeof this.user !== "undefined" && this.user !== null;
            },

            hasRoles:function () {
                return ((this.hasUser && typeof this.user.roles !== "undefined" && this.user.roles !== null) && (typeof this.user.roles.data !== "undefined" && this.user.roles.data !== null  && this.user.roles.data.length > 0));
            },

            userRole:function(){
                if(!this.hasRoles)
                {
                    return null;
                }

                return this.user.roles.data[0];

            },

            hasSupplier:function () {
                return typeof this.supplier !== "undefined" && this.supplier !== null;
            },
            hasSuppliers:function () {
                return typeof this.suppliers !== "undefined" && this.suppliers !== null && this.suppliers.length;
            },
            mustRefreshSuppliersList: function()
            {
                return (!this.isProcessing && !this.hasSuppliers);
            },
            current_page: function()
            {
                if(!this.hasSuppliers)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.current_page === "undefined" || this.pagination.current_page === null)
                {
                    return null;
                }
                return this.pagination.current_page;
            },
            total_pages: function()
            {
                if(!this.hasSuppliers)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.total_pages === "undefined" || this.pagination.total_pages === null)
                {
                    return null;
                }
                return this.pagination.total_pages;
            },
            next_page: function()
            {
                if(typeof this.current_page === "undefined" || this.current_page === null)
                    return null;

                if(typeof this.total_pages === "undefined" || this.total_pages === null)
                    return null;

                if(this.total_pages <= this.current_page)
                    return null;

                return (this.current_page+1);
            },
            showNext:function ()
            {
                return (typeof this.next_page !== "undefined" && this.next_page !== null);
            },
        },
        watch:{

            "selected_district_key": function (_value) {


               // this.$store.dispatch("SET_CITIES_LIST",null);
                this.selected_district = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_districts.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        this.getCities(_value);
                        console.log("Found District: "+_arrFound[0].id);
                        this.selected_district = _arrFound[0];

                    }
                }
            },
            "selected_city_key": function (_value) {

                this.selected_city = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_cities.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        //this.getWards(_value);
                        console.log("Found City: "+_arrFound[0].id);
                        this.selected_city = _arrFound[0];

                    }
                }
            }


        }
    }
</script>
