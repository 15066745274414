<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div
            ref="div_container"
            class="col s12 no-padding"
            :id="id"
            v-closable="{exclude: [],handler: 'onClose'}">
        <div ref="div_selected"
             class="col s12 no-padding border-bottom-1 grey-border font-12 align-middle"
             v-show="!is_selected"
        >
            <div
                    @click="triggerSelection"
                    class="col s11 content font-size-l padding-bottom-half padding-top-1"
            >
                {{selected_title}}
            </div>
            <div @click="triggerSelection"
                 v-show="!is_expanded"
                 class="col s1 no-padding center-align close padding-top-half padding-bottom-half"
            >
                <i class="material-icons">expand_more</i>
            </div>
            <div @click="clearSelection"
                 v-show="is_expanded"
                 class="col s1 no-padding center-align close padding-top-half padding-bottom-half"
            >
                <i class="material-icons tiny">close</i>
            </div>
        </div>
        <div ref="div_select_box"
             class="col s12 no-padding position-relative"
             v-if="(is_selected == true)">
            <div class="col s12 no-padding">
                <div class="col s10 input-field vert-offset-top-0">
                    <input ref="input_filter"
                           type="text"
                           data-length="100"
                           v-model="filter_value"
                           :id="input_filter_id"
                           v-on:keyup.enter.prevent="onEnter"
                    />
                </div>
                <div class="col s2">
                    <a @click.prevent="cancelSelection"
                       ref="btn_cancel_selection"
                       class="answer btn btn-tiny no-padding center-align close grey black-text">
                        <i class="material-icons tiny">close</i>
                    </a>
                </div>

            </div>

            <div class="col s12 no-padding dropdown-content">
                <div ref="div_select_list" class="col s12 no-padding">

                    <span class="col s12">
                        <label for="selectall">
                            <input type="checkbox" name="select-all" class="validate" id="selectall"  v-model="all_selected_keys" />
                            <span>Select All</span>
                        </label>
                    </span>
                    <span v-for="(opt, index) in options" v-show="arrVisibleOptions.indexOf(opt)>=0" class="col s12" v-bind:key="index">
                        <label :for="getCheckBoxId(opt)">
                            <input type="checkbox" :name="getName(opt)" class="validate" :id="getCheckBoxId(opt)"  v-model="selected_keys" :value="opt.key"/>
                            <span>{{getName(opt)}}</span>
                        </label>
                    </span>
                    <!--  <div v-for="opt in options"
                           class="col s12 m6 l4" v-show="arrVisibleOptions.indexOf(opt)>=0">
                          <check-box-component v-on:toggleSelect="toggleSelectFilter(opt)"  :id="getCheckBoxId(opt)"  :label="getName(opt)" name="list_options" v-model="opt.key"></check-box-component>
                      </div>-->
                    <!--  <input type="checkbox"

                           :value="opt.key"
                           class="col s12 no-padding" "

                      />-->
                    <!--  <a @click.prevent="selectOption(opt)"
                         class="answer btn btn-block btn-option bold vert-offset-bottom-1 white black-text font-10"

                      >
                          {{ getName(opt)}}
                      </a>-->
                </div>
                <div class="col s12 vert-offset-top-half right-align">
                    <a @click.prevent="cancelSelection"
                       class="answer btn btn-tiny  bold vert-offset-bottom-1   sp-gold font-10 vert-offset-right-half" >
                        Close
                    </a>
                    <a @click.prevent="confirmSelection"
                       class="answer btn btn-tiny  bold vert-offset-bottom-1 sp-gold   font-10 vert-offset-right-half" >
                        Add
                    </a>
                </div>
            </div>



        </div>
    </div>
</template>

<script>
    //import CheckBoxComponent from "@/components/materialize/CheckBoxComponent";
    import Vue from 'vue';

    let handleOutsideClick;

    Vue.directive('closable', {
        bind (el, binding, vnode) {
            // Here's the click/touchstart handler
            // (it is registered below)
            handleOutsideClick = (e) => {
                e.stopPropagation();
                // Get the handler method name and the exclude array
                // from the object used in v-closable
                const { handler, exclude } = binding.value;

                // This variable indicates if the clicked element is excluded
                let clickedOnExcludedEl = false;
                exclude.forEach(refName => {
                    // We only run this code if we haven't detected
                    // any excluded element yet
                    if (!clickedOnExcludedEl) {
                        // Get the element using the reference name
                        const excludedEl = vnode.context.$refs[refName];
                        // See if this excluded element
                        // is the same element the user just clicked on
                        clickedOnExcludedEl = excludedEl.contains(e.target)
                    }
                });

                // We check to see if the clicked element is not
                // the dialog element and not excluded
                if (!el.contains(e.target)) {
                    // If the clicked element is outside the dialog
                    // and not the button, then call the outside-click handler
                    // from the same component this directive is used in
                    vnode.context[handler]()
                    //handler(e);
                }
            };
            // Register click/touchstart event listeners on the whole page
            document.addEventListener('click', handleOutsideClick);
            document.addEventListener('touchstart', handleOutsideClick);
        },

        unbind () {
            // If the element that has v-closable is removed, then
            // unbind click/touchstart listeners from the whole page
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        }
    });
    export default {
        name: "custom-multi-select-component",
        components:{/*CheckBoxComponent*/},
        props: {
            title: {
                type: String
            },
            options: {
                type: Array
            },
            id:
                {
                    type:String,
                    default:""
                },
            value: {
                type: [Number, String,Array],
                default: ""
            },
            searchStart:{
                type:Boolean,
                default:true
            },
            allSelected:{
                type:Boolean,
                default:false
            },
        },
        data() {
            return {
                instance: undefined,
                is_selected : false,
                is_expanded: false,
                arrVisibleOptions: [],
                filter_value: "",
                selected_option: null,
                selected_title: "",
                selected_value : "",
                selected_keys:[],
                selected_names:[],
                all_selected_keys:false
            };
        },
        mounted() {
            this.selected_value = this.value;
            this.init();
            this.$el.addEventListener("change", this.changeHandler);
        },
        methods: {

            onClose () {
                /*this.selected_title =this.title;

                 if(typeof this.selected_keys !== "undefined" && this.selected_keys !== null && this.selected_keys.length > 0 )
                 {
                 let $this = this;

                 for(var r=0;r<this.selected_keys.length;r++)
                 {
                 var _filteredKeys = this.arrVisibleOptions.filter(function(_item)
                 {
                 return (_item.key == $this.selected_keys[r]);
                 });

                 if(_filteredKeys.length > 0)
                 {
                 let selected_option = _filteredKeys[0];
                 console.log("Filtered: "+ selected_option);
                 $this.selected_names.push(selected_option.name);
                 }
                 }
                 this.selected_title = this.selected_names.join(",");
                 let arr_selection = {"keys":this.selected_keys,"names":this.selected_names};
                 this.$emit("toggleSelectKeys",arr_selection);
                 }*/
                this.is_selected = false;
                this.filter_value = "";
            },
            toggleSelectFilter: function(_opt)
            {
                console.log("Toggle Box option: "+ JSON.stringify(_opt));
                console.log("Toggle Box key: "+ _opt.key);
                //let checkbox = window.document.getElementById("checkbox_"+_opt.key);
                if(typeof _opt.key === "undefined" || _opt.key === null || _opt.key.length <= 0)
                {
                    return false;
                }
                if(typeof this.selected_names === "undefined" || this.selected_names === null)
                {
                    this.selected_names = new Array();
                }

                if(this.selected_names.indexOf(_opt.key)>=0)
                {
                    window.console.log("Splice from component "+ this.selected_names.indexOf(_opt.key));
                    //this.selected_keys.splice(this.selected_keys.indexOf(_opt.key),1);
                    this.selected_names.splice(this.selected_names.indexOf(_opt.name),1);
                    this.selected_title = this.selected_names.join(",");


                }
                else
                {
                    //this.selected_keys.push(_opt.key);
                    this.selected_names.push(_opt.name);
                    this.selected_title = this.selected_names.join(",");
                }
                window.console.log("selected boxes:  "+ JSON.stringify(this.selected_names));
            },
            getCheckBoxId: function(_opt)
            {
                if(typeof _opt === "undefined" || _opt === null)
                    return null;
                return "checkbox_"+_opt.key;
            },
            getName(option)
            {
                if(typeof option.name !== "undefined" && option.name !== null)
                {
                    return option.name;
                }
                else if(typeof option.title !== "undefined" && option.title !== null)
                {
                    return option.title;
                }
                else
                {
                    return "[Invalid Option]";
                }
            },
            selectOption(option)
            {
                this.selected_option = option;
                this.is_expanded = true;
                this.is_selected = false;
            },
            cancelSelection()
            {
                this.is_selected = false;
                this.selected_keys =[];
                this.filter_value = "";
                this.selected_title = this.title;
                this.selected_names = [];
                let arr_selection = {"keys":this.selected_keys,"names":this.selected_names};
                this.$emit("toggleSelectKeys",arr_selection);
            },
            confirmSelection()
            {
                this.selected_title = this.title;

                if(typeof this.selected_keys !== "undefined" && this.selected_keys !== null && this.selected_keys.length > 0 )
                {
                    let $this = this;

                    this.selected_names = new Array();
                    for(var r=0;r<this.selected_keys.length;r++)
                    {
                        var _filteredKeys = this.arrVisibleOptions.filter(function(_item)
                        {
                            return (_item.key == $this.selected_keys[r]);
                        });

                        if(_filteredKeys.length > 0)
                        {
                            let selected_option = _filteredKeys[0];
                            console.log("Filtered: "+ selected_option);
                            $this.selected_names.push(selected_option.name);
                        }
                    }
                    this.selected_title = this.selected_names.join(",");
                    let arr_selection = {"keys":this.selected_keys,"names":this.selected_names};
                    this.$emit("toggleSelectKeys",arr_selection);
                }
                this.is_selected = false;
                this.filter_value = "";
                //this.selected_keys =[];
                //this.selected_names=[];
            },
            clearSelection()
            {
                this.selected_title = this.title;
                this.selected_value = "";
                this.is_expanded = false;
                this.filter_value = "";
                this.selected_keys =[];
                this.selected_names =[];
                let arr_selection = {"keys":this.selected_keys,"names":this.selected_names};
                this.$emit("toggleSelectKeys",arr_selection);

            },
            clearInput()
            {
                this.selected_title = this.title;
                this.selected_value = "";
                this.is_expanded = false;
                this.filter_value = "";
                this.selected_keys =[];
                this.selected_names =[];

                let arr_selection = {"keys":this.selected_keys,"names":this.selected_names};
                this.$emit("toggleSelectKeys",arr_selection);

            },
            expandSelection()
            {
                this.$refs.content_selector.trigger("click");
            },
            triggerSelection()
            {

                this.is_selected = true;

                console.log("Open selection: ");
                /*if(typeof this.input_filter_id !== "undefined" && this.input_filter_id !== null && this.input_filter_id.length > 0)
                 {
                 console.log("trigger selection");

                 window.document.getElementById(this.input_filter_id).focus();
                 }*/
                //this.$refs.input_filter.focus();


            },
            onEnter:function(){
                if(typeof this.arrVisibleOptions !== "undefined" && this.arrVisibleOptions !== null && this.arrVisibleOptions.length > 0)
                {
                    this.toggleSelectFilter(this.arrVisibleOptions[0].key);
                }
            },

            initTitle()
            {
                if(typeof this.title !== "undefined" && this.title !== null && this.title.length > 0)
                    this.selected_title = this.title;
                else
                    this.selected_title = "Select Option";
                this.selected_value = "";
            },
            init()
            {
                this.instance = this;
                this.arrVisibleOptions = this.options;
                this.initTitle();
                this.selected_keys = [];
                this.selected_names = [];
                if(typeof this.value !== "undefined" && this.value !== null && this.value.length > 0)
                {
                    var _instance = this;
                    var _filteredList = this.arrVisibleOptions.filter(function(_item)
                    {
                        return ((typeof _item.value !== "undefined" && _item.value !== null && _item.value == _instance.value) || (typeof _item.key !== "undefined" && _item.key !== null && _item.key == _instance.value));
                    });

                    if(_filteredList.length > 0)
                    {
                        this.selected_option = _filteredList[0];
                        console.log("Filtered: "+this.selected_option);
                    }
                }

            },
            changeHandler({ target }) {
                console.log("Input Change: " + target.value);
                 /*this.$emit("input", target.value);*/
            },

        },
        computed:{
            'input_filter_id':function () {

                console.log("ÏD:"+this.id);
                if(typeof this.id === "undefined" || this.id === null || this.id.length <= 0)
                {
                    return null;
                }

                return this.id+"_input_filter";

            }
        },
        watch:
            {
                'all_selected_keys':function (_value) {
                    console.log("Selected All: "+_value);

                    let $this = this;

                    console.log("Selected Keys: "+this.selected_keys);
                    if(_value)
                    {
                        console.log("Selected Keys: "+this.selected_keys);

                    }
                    this.selected_names = new Array();
                    for(var r=0;r<this.selected_keys.length;r++)
                    {
                        var _filteredKeys = this.arrVisibleOptions.filter(function(_item)
                        {
                            return (_item.key == $this.selected_keys[r]);
                        });

                        if(_filteredKeys.length > 0)
                        {
                            let selected_option = _filteredKeys[0];
                            console.log("Filtered: "+ selected_option);
                            $this.selected_names.push(selected_option.name);
                        }
                    }
                    this.selected_title = this.selected_names.join(",");
                    let arr_selection = {"keys":this.selected_keys,"names":this.selected_names};
                    this.$emit("toggleSelectKeys",arr_selection);
                },
                'options':function (_value) {
                    this.arrVisibleOptions = _value;
                    this.init();
                },
                'filter_value': function(_value)
                {

                    if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                    {
                        this.arrVisibleOptions = this.options;
                        return false;
                    }

                    let $this = this;
                    this.arrVisibleOptions = this.options.filter(function(_item)
                    {
                        return ((!$this.searchStart && _item.name.toLowerCase().includes(_value.toLowerCase()) ) || _item.name.toLowerCase().startsWith(_value.toLowerCase()));
                    });

                    console.log("Arr visible: "+JSON.stringify(this.arrVisibleOptions))

                },
                'selected_keys' : function(_value)
                {
                    if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                    {
                        //this.initTitle();

                    }
                },
                'selected_value': function(_value)
                {
                    this.$emit("input", _value);
                    if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                        this.arrVisibleOptions = this.options;
                },
                'selected_option': function(_value)
                {
                    if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                    {
                        this.initTitle();
                    }
                    else
                    {
                        if(typeof _value.key !== "undefined" && _value.key !== null)
                        {
                            this.selected_title = _value.name;
                            this.selected_value = _value.key;
                        }
                        else if(typeof _value.value !== "undefined" && _value.value !== null)
                        {
                            this.selected_title = _value.title;
                            this.selected_value = _value.value;
                        }

                    }
                }
            },
        destroyed() {
            this.$el.removeEventListener("change", this.changeHandler);
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .btn-option
    {
        line-height: normal !important;
        height: auto !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        margin-bottom:2px !important;
    }

    .buttons-box{
        position: absolute; /*newly added*/
        right: 5px; /*newly added*/
        top: 5px;/*newly added*/
    }
    .dropdown-content {
        display: block;
        left: 0px;
        top: 50px;
        max-height: 250px;
        transform-origin: 0px 0px 0px;
        opacity: 1;
        transform: scaleX(1) scaleY(1);
        background-color: #fff;
        margin: 0;
        max-width: 500px;
        overflow-y: auto;
        position: absolute;
        z-index: 9999;
    }
    .position-relative{
        position: relative;
    }

</style>