<template>

 <registration-component></registration-component>
</template>
<script>
    // @ is an alias to /src
    import RegistrationComponent from '@/components/suppliers/RegistrationComponent.vue'

    export default {
        name: 'RegisterSupplier',
        components: {
            RegistrationComponent
        }
    }
</script>
