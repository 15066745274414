<template>

 <view-supplier-component></view-supplier-component>
</template>
<script>
    // @ is an alias to /src
    import ViewSupplierComponent from '@/components/suppliers/ViewSupplierComponent.vue'

    export default {
        name: 'ViewSupplier',
        components: {
            ViewSupplierComponent
        }
    }
</script>
