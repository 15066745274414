<template>
    <div class="row padding-top-half">
        <div class="col s12 m10 offset-m1 left-align">
            <div class="header right-align">
                <!-- <button class="btn-small rounded-15 btn font-9 sp-gold" @click.prevent="updateProfile">
                     &lt;!&ndash;
                                                 <i class="material-icons tiny sp-maroon-text">edit</i>
                     &ndash;&gt;
                     <span class="padding-bottom-1" v-if="!showContactEdit">Update Profile</span>
                     <span class="padding-bottom-1" v-else><i class="material-icons sp-maroon-text font-11">cancel</i>Cancel Update Profile</span>
                 </button>-->
            </div>
            <div class="col s12 no-padding">
                <div class="col s12 padding-top-half padding-bottom-half padding-left-half">
                    <div class="col s12 no-padding right-align">
                        <a class="sp-maroon-text clickable underline rounded-15 text-transform-normal font-10" @click.prevent="toggleDetailsForm" >
                            <span v-if="!showDetailsForm"><i class="material-icons sp-maroon-text font-11">edit</i> Edit</span>
                            <span v-if="showDetailsForm"><i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                        </a>
                    </div>
                    <div class="col s12 no-padding valign-wrapper">
                        <div class="col s12 m2">
                            <img src="../../assets/img/logo.png" alt="" class="circle responsive-img sp-blue">
                            <div class="font-10 center" v-if="showDetailsForm">
                                <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleLogoForm" >
                                    <span v-if="!showLogoForm"> <i class="material-icons sp-maroon-text font-11">edit</i> Edit</span>
                                    <span v-if="showLogoForm"> <i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                                </a>
                            </div>

                        </div>
                        <div class="col s12 m10">
                            <div class="col s12">
                                <div class="col s12 no-padding">
                                    <div class="col s12 no-padding left-align font-20">
                                        {{supplier.name}}
                                    </div>
<!--
                                    <div class="col s3 no-padding font-10 right-align" v-show="showDetailsForm">
                                        <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleNameForm" >
                                            <span v-if="!showNameForm"> Edit</span>
                                            <span v-if="showNameForm"> <i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                                        </a>
                                    </div>
-->
                                </div>
                                <div class="col s12 padding-bottom-1 padding-top-1" v-if="showNameForm && showDetailsForm">
                                    <div class="col s12 padding-bottom-1 padding-top-1">
                                        <form @submit.prevent="confirmSaveName">

                                            <div class="col s12 vert-offset-top-1">
                                                <input-component v-model.trim="$v.name.$model" type="text" id="name" label="Business Name" :class="{'is-valid': submitted && $v.name.$error, 'is-valid': submitted && !$v.name.$invalid}"></input-component>
                                                <div class="col s12 vert-offset-left-1 form-error">
                                            <span v-if="$v.name.$dirty && !$v.name.required && submitted">
                                                Name is required.
                                            </span>

                                                    <span v-if="$v.name.$dirty && !$v.name.minLength">
                                                Name is too short, your business name should be at least {{$v.name.$params.minLength.min }} characters or more.
                                            </span>

                                                    <span v-if="$v.name.$dirty && !$v.name.maxLength">
                                                Name is too long, your business name should not be more than {{$v.name.$params.maxLength.max }} characters.
                                            </span>
                                                </div>
                                            </div>

                                            <div class="col s12 vert-offset-top-1 right-align">
                                                <button type="submit" class="hoverable btn btn-small sp-gold rounded-15 black-text">
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col s12 padding-top-half">
                                <div class="col s12 no-padding">
                                    <div class="col s9 no-padding font-12 left-align">
                                        <span>{{supplier.city.data.name}}&nbsp;<i class="tiny material-icons font-9">lens</i>&nbsp;</span>
                                        <span>{{supplier.city.data.district.data.name}}&nbsp;<i class="tiny material-icons font-9">lens</i>&nbsp;</span>
                                        <span>{{supplier.city.data.country.name}}</span>
                                    </div>
                                    <div class="col s3 no-padding font-10 right-align" v-show="showDetailsForm">
                                        <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleLocalityForm" >
                                            <span v-if="!showLocalityForm"><i class="material-icons sp-maroon-text font-11">edit</i> Edit</span>
                                            <span v-if="showLocalityForm"><i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="col s12 padding-bottom-1 padding-top-1" v-if="showLocalityForm">
                                    <div class="col s12 padding-bottom-1 padding-top-1">
                                        <form @submit.prevent="confirmSaveLocality">

                                            <div class="col s12 vert-offset-top-1">
                                                <div class="col s12 m4" v-if="countriesExists">
                                                    <div class="row vert-offset-bottom-1 padding-right-half">
                                                        <custom-select-component :options="list_countries"  v-model.trim="$v.selected_country_key.$model" title="Type to select the country" :searchStart="false" :class="{'is-valid': $v.selected_country_key.$error, 'is-valid': !$v.selected_country_key.$invalid}">
                                                        </custom-select-component>
                                                        <div class="col s12 vert-offset-left-3 form-error">
                                                            <span v-if="$v.selected_country_key.$dirty && !$v.selected_country_key.required">Country is required.</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col s12 m4" v-if="districtsExists">
                                                    <div class="row vert-offset-bottom-1 padding-right-half">
                                                        <custom-select-component id="districts" :options="list_districts" v-model.trim="$v.selected_district_key.$model" title="Type to select your District" :searchStart="false"
                                                                                 :class="{'is-valid': $v.selected_district_key.$error, 'is-valid': !$v.selected_district_key.$invalid}">
                                                        </custom-select-component>
                                                        <div class="col s12 vert-offset-left-3 form-error">
                                                            <span v-if="$v.selected_district_key.$dirty && !$v.selected_district_key.required">District is required.</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col s12 m4" v-if="citiesExists">
                                                    <div class="row vert-offset-bottom-1 padding-right-half">
                                                        <custom-select-component id="cities" :options="list_cities" v-model="$v.selected_city_key.$model" title="Type to select a city" :searchStart="false" :class="{'is-valid': $v.selected_city_key.$error, 'is-valid': !$v.selected_city_key.$invalid}">
                                                        </custom-select-component>
                                                        <div class="col s12 vert-offset-left-3 form-error">
                                                            <span v-if="$v.selected_city_key.$dirty && !$v.selected_city_key.required">City is required.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s12 vert-offset-top-1 right-align">
                                                <button type="submit" class="hoverable btn btn-small rounded-15 sp-gold black-text">Save</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col s12 padding-top-1">
                                <div class="col s12 no-padding">
                                    <div class="col s9 no-padding font-12 left-align">
                                        <span>{{supplier.email}}&nbsp;<i class="tiny material-icons font-9">lens</i>&nbsp;</span>
                                        <span>{{supplier.contact_number}}&nbsp;<i class="tiny material-icons font-9">lens</i>&nbsp;</span>
                                        <span>{{supplier.physical_address}}&nbsp;<i class="tiny material-icons font-9">lens</i> &nbsp;</span>
                                        <span>{{supplier.postal_address}}</span>
                                    </div>
                                    <div class="col s3 no-padding font-10 right-align" v-show="showDetailsForm">
                                        <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleContactForm" >
                                            <span v-if="!showContactForm"> <i class="material-icons sp-maroon-text font-11">edit</i> Edit</span>
                                            <span v-if="showContactForm"> <i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="col s12 padding-bottom-1 padding-top-1" v-if="showContactForm">
                                    <form @submit.prevent="confirmSaveContacts">

                                        <!-- <div class="col s12 vert-offset-top-1">
                                             <custom-select-component :options="list_businesstypes"  v-model.trim="$v.selected_businesstype_key.$model" title="Type to select a business type" :searchStart="false" :class="{'is-valid': $v.selected_businesstype_key.$error, 'is-valid': !$v.selected_businesstype_key.$invalid}">
                                             </custom-select-component>
                                             <div class="col s12 vert-offset-left-1 form-error">
                                                 <span v-if="$v.selected_businesstype_key.$dirty && !$v.selected_businesstype_key.required">Business Type  is required.</span>
                                             </div>
                                         </div>-->
                                        <div class="col s12 vert-offset-top-1">
                                            <div class="col s12 m6">
                                                <input-component  v-model.trim="$v.email.$model" type="email" id="email" label="E-Mail Address" :class="{'is-valid': submitted && $v.email.$error,'is-valid': submitted && !$v.email.$invalid}" :disabled="hasEmail"></input-component>
                                                <div class="col s12 vert-offset-left-1 form-error">
                                                    <span v-if="$v.email.$dirty && !$v.email.required">Email is required</span>
                                                    <span v-if="$v.email.$dirty && !$v.email.email">Your email is invalid</span>
                                                </div>

                                            </div>
                                            <div class="col s12 m6">
                                                <InputComponent v-model.trim="$v.mobile.$model" :class="{'is-valid': $v.mobile.$error,'is-valid': !$v.mobile.$invalid}" type="tel" label="Contact Number">
                                                </InputComponent>
                                                <div class=" col s12 vert-offset-left-1 form-error">
                                                    <span v-if="$v.mobile.$dirty && !$v.mobile.required">Contact number is required.</span>
                                                    <span  v-if="$v.mobile.$dirty && !$v.mobile.minLength">Contact number should have at least {{ $v.mobile.$params.minLength.min }} digits.</span>
                                                    <span v-if="$v.mobile.$dirty && !$v.mobile.maxLength">Contact number should not have more than {{ $v.mobile.$params.maxLength.max }} digits.</span>
                                                    <span v-if="$v.mobile.$dirty && !$v.mobile.numeric">Contact number should be {{ $v.mobile.$params.numeric.max }} digits.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col s12 vert-offset-top-1">
                                            <input-component v-model.trim="$v.physical_address.$model" :class="{'is-valid': $v.physical_address.$error,'is-valid': !$v.physical_address.$invalid }" type="text" label="Physical Address">
                                            </input-component>
                                            <div class="col s12 vert-offset-left-1 form-error">
                                            <span v-if="$v.physical_address.$dirty && !$v.physical_address.maxLength">Physical Address should not have more than {{ $v.physical_address.$params.maxLength.max }} characters.
                                            </span>
                                                <span v-if="$v.physical_address.$dirty && !$v.physical_address.maxLength">Physical Address should have at least {{ $v.physical_address.$params.minLength.min }} characters.
                                            </span>
                                                <span v-if="$v.physical_address.$dirty && !$v.physical_address.required">Physical Address is required.
                                            </span>
                                            </div>
                                        </div>
                                        <div class="col s12 vert-offset-top-1">
                                            <InputComponent v-model.trim="$v.postal_address.$model" :class="{'is-valid': $v.postal_address.$error, 'is-valid': !$v.postal_address.$invalid}" type="text" label="Postal Address" >
                                            </InputComponent>
                                            <div class="col s12 vert-offset-left-1 form-error">

                                                <span v-if="$v.postal_address.$dirty && !$v.postal_address.required">Postal Address is required.</span>
                                                <span v-if="$v.postal_address.$dirty && !$v.postal_address.maxLength">Postal Address should not have more than {{ $v.postal_address.$params.maxLength.max }} characters.
                                            </span>
                                                <span v-if="$v.postal_address.$dirty && !$v.postal_address.minLength">Postal Address should have at least {{ $v.postal_address.$params.minLength.min}} characters.
                                            </span>
                                            </div>
                                        </div>

                                        <div class="col s12 vert-offset-top-1 right-align">
                                            <button type="submit" class="hoverable btn btn-small rounded-15 sp-gold black-text">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col s12 padding-bottom-1 padding-top-1" v-if="showLogoForm">
                                <form @submit.prevent="confirmSaveLogo">

                                    <div class="col s12 vert-offset-top-1">
                                        <img width="200" height="200" :src="picture" v-if="isPicture" ref="logoImage"/>

                                        <div class="file-field input-field">
                                            <div class="btn yellow darken-2 black-text">
                                                <span>Logo</span>
                                                <input type="file" :class="{ 'is-invalid': $v.logo.$error }" accept="image/*" @change="uploadImage"/>
                                            </div>
                                            <div class="file-path-wrapper">
                                                <input class="file-path validate" type="text"/>
                                            </div>
                                        </div>
                                        <div class="col s12 vert-offset-left-1 form-error">
                                            <span v-if="$v.logo.$dirty && !$v.logo.required && submitted">
                                                Logo is required.
                                            </span>
<!--
                                            <span v-if="$v.logo.$dirty && !$v.logo.isValidSize && submitted">
                                                File is too big.
                                            </span>
-->
                                        </div>
                                    </div>

                                    <div class="col s12 vert-offset-top-1 right-align">
                                        <button type="submit" class="hoverable btn btn-small sp-gold black-text rounded-15">Save</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12 no-padding">
                <div class="col s12 m6 padding-bottom-half">
                    <div class="col s12 card-panel rounded-5 padding-bottom-1 padding-top-1">
                        <div class=" col s12 no-padding">
                            <div class="col s9 grey-text text-darken-1 no-padding font-11">Business Structure</div>
                            <div class="col s3 no-padding right-align font-10">
                                <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="togglePatronageForm" >
                                    <span v-if="!showPatronageForm"><i class="material-icons sp-maroon-text font-11">edit</i> Edit</span>
                                    <span v-if="showPatronageForm"><i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                                </a>
                            </div>
                        </div>
                        <div class="col s12 no-padding" v-if="hasEntities">
                            <div class="font-12">{{supplier.entity.data.name}}</div>
                        </div>
                        <div class="col s12 no-padding" v-if="!hasEntities">
                            <div class="font-12 form-error">Missing information</div>
                        </div>
                        <div class="col s12 no-padding" id="patronage-form" v-if="showPatronageForm">
                            <div class="col s12 vert-offset-top-1">
                                <form class="col s12" @submit.prevent="confirmSavePatronageType">
                                    <div class="row vert-offset-bottom-1 padding-right-half">
                                        <custom-select-component :options="list_patronagetypes"  v-model.trim="$v.selected_patronagetype_key.$model" title="Type to select your business structure" :searchStart="false" :class="{'is-valid': $v.selected_patronagetype_key.$error, 'is-valid': !$v.selected_patronagetype_key.$invalid}">
                                        </custom-select-component>
                                        <div class="col s12 form-error">
                                            <span v-if="$v.selected_patronagetype_key.$dirty && !$v.selected_patronagetype_key.required">Business structure is required.</span>
                                        </div>
                                    </div>

                                    <div class="col s12 vert-offset-top-1 right-align">
                                        <button class="btn-small right-align sp-gold rounded-15 hoverable" type="submit">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col s12 m6  padding-bottom-half">
                    <div class="col s12 card-panel rounded-5 padding-bottom-1 padding-top-1 ">

                        <div class="col s12 no-padding">

                            <div class="col s9 grey-text text-darken-1 no-padding font-11">Local Ownership Percentage</div>
                            <div class="col s3 no-padding right-align font-10">
                                <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleOwnershipForm">
                                    <span v-if="!showOwnershipForm"><i class="material-icons sp-maroon-text font-11">edit</i> Edit</span>
                                    <span v-if="showOwnershipForm"><i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                                </a>
                            </div>
                        </div>
                        <div class="col s12 font-12 no-padding" v-if="hasOwnership">{{supplier.ownership.data.name}}</div>
                        <div class="col s12 no-padding" v-if="!hasOwnership">
                            <div class="font-12 form-error">Missing information</div>
                        </div>


                        <div class="col s12 no-padding" v-if="showOwnershipForm">
                            <div class="col s12 vert-offset-top-1">
                                <form class="col s12" @submit.prevent="confirmSaveOwnershipType">
                                    <div class="row vert-offset-bottom-1 padding-right-half">
                                        <custom-select-component :options="list_ownershiptypes"  v-model.trim="$v.selected_ownershiptype_key.$model" title="Type to select your business ownership structure" :searchStart="false" :class="{'is-valid': $v.selected_ownershiptype_key.$error, 'is-valid': !$v.selected_ownershiptype_key.$invalid}">
                                        </custom-select-component>
                                        <div class="col s12 vert-offset-left-3 form-error">
                                            <span v-if="$v.selected_ownershiptype_key.$dirty && !$v.selected_ownershiptype_key.required">Business ownership type is required.</span>
                                        </div>
                                    </div>

                                    <div class="col s12 vert-offset-top-1 right-align">
                                        <button type="submit" class="btn-small black-text sp-gold rounded-15">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col s12">
                <div class="col s12">
                    <div class="col s9 grey-text text-darken-1 padding-left-0">Industries</div>
                    <div class="col s3 no-padding font-10 right-align" >
                        <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleIndustriesForm">
                            <span v-if="!showIndustriesForm"><i class="material-icons sp-maroon-text font-11">edit</i> Edit</span>
                            <span v-if="showIndustriesForm"><i class="material-icons sp-maroon-text font-11">cancel</i>Cancel</span>
                        </a>
                    </div>
                </div>
                <div class="col s12 card-panel rounded-5 padding-top-1 padding-bottom-1">
                    <div class="col s12 no-padding" v-if="hasIndustries">
                    <span v-for="(item , index) in supplier.industries.data" :key="index">
                        <span class="chip font-11">{{item.name}}</span>
                    </span>
                    </div>
                    <div class="col s12 no-padding" v-if="!hasIndustries">
                        <div class="font-12 form-error">Missing information</div>
                    </div>

                    <div class="col s12 no-padding" v-if="showIndustriesForm">
                        <div class="col s12 vert-offset-top-1">
                            <form class="col s12" @submit.prevent="confirmSaveIndustry">
                                <div class="row vert-offset-bottom-1 padding-right-half">
                                    <custom-multi-select-component :options="list_industries"  v-model.trim="$v.selected_industry.$model" title="Type to select your the industry" :searchStart="false" :class="{'is-valid': $v.selected_industry.$error, 'is-valid': !$v.selected_industry.$invalid}" v-on:toggleSelectKeys="getSelectedIndustries">
                                    </custom-multi-select-component>
                                    <div class="col s12 vert-offset-left-3 form-error">
                                        <span v-if="submitted && !$v.selected_industry.minLength">Select at  least {{$v.selected_industry.minLength.min}} industry.</span>
                                    </div>
                                </div>

                                <div class="col s12 right-align">
                                    <button type="submit" class="btn-small sp-gold rounded-15">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col s12">
                <div class="col s12">
                    <div class="col s9 grey-text text-darken-1 no-padding ">Sectors</div>
                    <div class="col s3 no-padding font-10 right-align">
                        <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleSectorsForm">
                            <span v-if="!showSectorsForm">
                                <i class="material-icons sp-maroon-text font-11">edit</i> Edit
                            </span>
                            <span v-if="showSectorsForm">
                               <i class="material-icons sp-maroon-text font-11">cancel</i>Cancel
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col s12  card-panel rounded-5  padding-top-1 padding-bottom-1">
                    <div class="col s12 no-padding" v-if="hasSectors">
                    <span v-for="(item, index) in supplier.sectors.data" :key="index">
                        <span class="chip font-11">{{item.name}}</span>
                    </span>
                    </div>
                    <div class="col s12 no-padding" v-if="!hasSectors">
                        <div class="font-12 form-error">Missing information</div>
                    </div>
                    <div class="col s12 no-padding" v-if="showSectorsForm">
                        <div class="col s12 vert-offset-top-1">
                            <form class="col s12" @submit.prevent="confirmSaveSector">
                                <div class="row vert-offset-bottom-1 padding-right-half">
                                    <custom-multi-select-component :options="list_sectors"  v-model.trim="$v.selected_sector.$model" title="Type to select your business sector" :searchStart="false" :class="{'is-valid': $v.selected_sector.$error, 'is-valid': !$v.selected_sector.$invalid}" v-on:toggleSelectKeys="getSelectedSectors">
                                    </custom-multi-select-component>
                                    <div class="col s12 vert-offset-left-3 form-error">
                                        <span v-if="submitted && !$v.selected_sector.minLength">Select at least {{$v.selected_sector.minLength.min}}.</span>
                                    </div>
                                </div>

                                <div class="col s12 right-align">
                                    <button type="submit"  class="btn-small sp-gold rounded-15">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12">

                <div class="col s12 card-panel rounded-5">

                    <div class="card-tabs no-padding">

                        <tab-component>
                            <li class="tab"><a class="active" href="#products">Products</a></li>
                            <li class="tab"><a  href="#certificates">Documents</a></li>
                            <li class="tab"><a  href="#metrics">Metrics</a></li>
                            <li class="tab"><a  href="#banking">Banking Details</a></li>
                        </tab-component>

                        <!-- <ul class="tabs tabs-fixed-width">
                             <li class="tab"><a class="active" href="#products">Products</a></li>
                             <li class="tab"><a  href="#certificates">Certificates</a></li>
                         </ul>-->
                    </div>

                    <div class="card-content">
                        <div id="products" class="padding-top-half">
                            <div class="row col s12 no-padding">
                                <div class="col s9 no-padding" v-if="hasProducts" >
                                    <span v-for="(item,i) in supplier.products.data" :key="i" v-show="!showProductsForm">
                                        <span class="chip font-11">{{item.name}}</span>
                                    </span>

                                </div>
                                <div class="col s3 no-padding font-10 right-align">
                                    <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleProductsForm">
                                    <span v-if="!showProductsForm">
                                        <i class="material-icons sp-maroon-text font-11">edit</i> Edit
                                    </span>
                                        <span v-if="showProductsForm">
                                        <i class="material-icons sp-maroon-text font-11">cancel</i>Cancel
                                    </span>
                                    </a>
                                </div>
                                <div class="col s12 no-padding" v-if="!hasProducts">
                                    <div class="font-12 form-error">Missing information</div>
                                </div>
                            </div>
                            <div class="col s12 no-padding" v-if="showProductsForm">
                                <div class="col s12 vert-offset-top-1">
                                    <form class="col s12" @submit.prevent="confirmSaveProduct">

                                        <div class="col s12 vert-offset-top-half vert-offset-bottom-1 border-bottom-1 grey-border border-lighten-2" v-if="list_products.length > 0">
                                            <span v-for="(item,i) in list_products" :key="i">
                                        <span class="chip font-11">{{item.name }}<i class="close material-icons" @click="removeProduct(item)">close</i></span>
                                    </span>
                                        </div>

                                        <div class="col s12" v-if="!resetProductsForm">
                                            <button @click.prevent="resetProductsForm = true" class="btn-small sp-gold rounded-15">Add More</button>
                                            <button type="submit" v-if="list_products.length > 0 && (!hasProducts || (hasProducts && list_products.length !== totalProducts))" class="btn-small sp-gold rounded-15">Save</button>
                                        </div>
                                        <div class="col s12 no-padding" v-if="resetProductsForm">
                                            <div class="row vert-offset-bottom-1 padding-right-half">
                                                <custom-select-component :options="list_producttypes"  v-model.trim="$v.selected_producttype_key.$model" title="Type to select a product/service type" :searchStart="false" :class="{'is-valid': $v.list_products.$error, 'is-valid': !$v.list_products.$invalid}" >
                                                </custom-select-component>
                                                <div class="col s12 vert-offset-left-3 form-error">
                                                    <span v-if="submitted && !$v.list_products.minLength">Please add at least {{$v.list_products.minLength.min}}.</span>
                                                </div>
                                            </div>
                                            <div class="col s12">
                                                <span class="font-10 grey-text text-darken-4 vert-offset-bottom-1">To add a word, press Enter/Return/Go after you are done typing.</span>
                                                <custom-chip-component :data="products_tags" v-model="selected_products_chips" v-on:chipData="chipData" :autocomplete="false" placeholder="Enter Product/Service" secondaryPlaceholder="+Product" :class="{'is-valid': $v.list_products.$error, 'is-valid': !$v.list_products.$invalid}">

                                                </custom-chip-component>
                                                <div class="col s12 vert-offset-left-3 form-error">
                                                    <span v-if="submitted && !$v.list_products.minLength">Please add at least {{$v.list_products.minLength.min}}.</span>
                                                </div>
                                            </div>
                                            <div class="col s12 right-align">
                                                <button class="btn-small sp-gold rounded-15" :disabled="selected_products_chips.length <= 0 || !hasSelectedProductTypeKey" @click.prevent="addProduct">Add</button>
                                                <button type="submit" v-if="list_products.length > 0 && list_products.length !== totalProducts" class="btn-small sp-gold rounded-15">Save</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div id="certificates" class="row padding-top-half">
                            <div class="col s12  font-10 right-align">
                                <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleDocsForm">
                                        <span v-if="!showDocsForm">
                                            <i class="material-icons sp-maroon-text font-11">edit</i> Edit
                                        </span>
                                    <span v-if="showDocsForm">
                                           <i class="material-icons sp-maroon-text font-11">cancel</i>Cancel
                                        </span>
                                </a>
                            </div>
                            <div class="col s12">
                                <div class="col s12 no-padding" v-if="hasDocuments">
                                     <ul class="collection" v-for="(item,i) in supplier.documents.data" :key="i">
                                         <li  class="col s12 collection-item font-11">
                                             <div class="col s12 no-padding"  v-if="!showDocsForm">
                                                 <div class=" col s12 m6 valign-wrapper" @click.prevent="openFile(item)">
                                                     <span class="underline">{{item.name}}</span>
                                                     <i class="material-icons clickable" v-if="!showDocsForm">file_download</i>
                                                 </div>
                                                 <div class="col s12 m6 right-align font-10">
                                                     <div class="col s12 no-padding" v-if="isSystemAdmin || isAdmin">
                                                         <a v-if="!item.verified" class="white-text edit-btn clickable red darken-2 rounded-15 text-transform-normal"  @click.prevent="confirmVerification(item)">Verify</a>
                                                         <span v-if="item.verified"><i class="material-icons green-text font-10" >check_circle</i>Verified</span>

                                                     </div>

                                                     <div class="col s12 no-padding" v-if="isBusinessUser || isStandard">
                                                         <span v-if="item.verified"><i class="material-icons green-text font-10" >check_circle</i>Verified</span>
                                                         <span v-if="!item.verified"><i class="material-icons red-text font-11" >cancel</i>Awaiting Verification</span>
                                                     </div>

                                                 </div>
                                             </div>
                                             <div class="valign-wrapper" v-if="showDocsForm" >
                                                 <span class="valign-wrapper">{{item.name}}<i class="close material-icons clickable" v-if="showDocsForm" @click.prevent="confirmDeleteFile(item)">close</i>
</span>
                                             </div>
                                         </li>
                                     </ul>
                                </div>

                            </div>
                            <div class="col s12 no-padding" v-if="showDocsForm">
                                <div class="col s12 vert-offset-top-1">
                                    <form class="col s12" @submit.prevent="confirmSaveDocument">
                                        <div class="row vert-offset-bottom-1 padding-right-half">
                                            <custom-select-component :options="list_doctypes"  v-model.trim="$v.selected_doctype_key.$model" title="Type to select a document type" :searchStart="false" :class="{'is-valid': $v.selected_doctype_key.$error, 'is-valid': !$v.selected_doctype_key.$invalid}" >
                                            </custom-select-component>
                                            <div class="col s12 vert-offset-left-3 form-error">
                                                <span v-if="submitted && !$v.selected_doctype_key.required">Document type is required.</span>
                                            </div>
                                        </div>
                                        <div class="col s12">
                                            <div class="file-field input-field">
                                                <div class="btn yellow darken-2 black-text">
                                                    <span>File</span>
                                                    <input type="file" :class="{ 'is-invalid': $v.doc_file.$error }" accept="application/pdf" @change="uploadPDF" ref="document_upload"/>
                                                </div>
                                                <div class="file-path-wrapper">
                                                    <input class="file-path validate" type="text"/>
                                                </div>
                                            </div>
                                            <div class="col s12 vert-offset-left-1 form-error">
                                            <span v-if="$v.doc_file.$dirty && !$v.doc_file.required && submitted">
                                                A document file is required.
                                            </span>
                                                <!--<span v-if="$v.doc_file.$dirty && !$v.doc_file.isValidSize && submitted">
                                                File is too big.
                                            </span>-->
                                            </div>
                                        </div>
                                        <div class="col s12 right-align">
                                            <button type="submit"  class="btn-small sp-gold rounded-15">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                        <div id="metrics" class="row padding-top-half">
                            <div class="col s12 font-10 right-align">
                                <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleMetricsForm">
                                        <span v-if="!showMetricsForm">
                                            <i class="material-icons sp-maroon-text font-11">edit</i> Edit
                                        </span>
                                    <span v-if="showMetricsForm">
                                            <i class="material-icons sp-maroon-text font-11">cancel</i>Cancel
                                        </span>
                                </a>
                            </div>
                            <div class="col s12">
                                <div class="col s12 no-padding" v-if="hasMetrics">
                                     <ul class="collection" v-for="(item,i) in supplier.metrics.data" :key="i">

                                         <li class="collection-item">

                                             <div class="col s12"  v-if="!showMetricsForm">
                                                 <div class=" col s12 m8 clickable valign-wrapper vert-offset-bottom-half">
                                                     <span>{{item.name}}</span><span class="badge sp-gold sp-maroon-text rounded-15 font-12">{{item.total}}</span>
                                                 </div>
                                                 <div class="col s12 m4 right-align font-10">
                                                     <div class="col s12 no-padding" v-if="isSystemAdmin || isAdmin">
                                                         <a v-if="!item.verified" class="white-text edit-btn clickable red darken-2 rounded-15 text-transform-normal"  @click.prevent="confirmMetricVerification(item)">Verify</a>
                                                         <span v-if="item.verified"><i class="material-icons green-text font-10" >check_circle</i>Verified</span>

                                                     </div>

                                                     <div class="col s12 no-padding" v-if="isBusinessUser || isStandard">
                                                         <span v-if="item.verified"><i class="material-icons green-text font-10" >check_circle</i>Verified</span>
                                                         <span v-if="!item.verified"><i class="material-icons red-text font-11" >cancel</i>Awaiting Verification</span>
                                                     </div>

                                                 </div>
                                             </div>
                                             <div class="valign-wrapper clickable" v-if="showMetricsForm" @click.prevent="confirmRemoveMetric(item)">
                                                 <span class="">{{item.name}}</span>
                                                 <i class="close material-icons clickable" v-if="showMetricsForm">close</i>
                                             </div>
                                             <!--<div class="col s12 vert-offset-bottom-half valign-wrapper">
                                                 <div class="s12 m6">
                                                     <span>{{item.name}}<span class="badge sp-gold sp-maroon-text rounded-15 font-12">{{item.total}}</span></span>
                                                 </div>

                                                 <div class="col s12 m6 right-align font-10">
                                                     <div class="col s12 no-padding" v-if="isSystemAdmin || isAdmin">
                                                         <a v-if="!item.verified" class="white-text edit-btn clickable red darken-2 rounded-15 text-transform-normal"  @click.prevent="confirmVerification(item)">Verify</a>
                                                         <span v-if="item.verified"><i class="material-icons green-text font-10" >check_circle</i>Verified</span>

                                                     </div>

                                                     <div class="col s12 no-padding" v-if="isBusinessUser || isStandard">
                                                         <span v-if="item.verified"><i class="material-icons green-text font-10" >check_circle</i>Verified</span>
                                                         <span v-if="!item.verified"><i class="material-icons red-text font-11" >cancel</i>Awaiting Verification</span>
                                                     </div>

                                                     <div class="col s2 no-padding right-align" v-if="showMetricsForm">
                                                         <i class="material-icons clickable"  @click.prevent="confirmRemoveMetric">close</i>
                                                     </div>
                                                 </div>

                                             </div>-->
                                         </li>
                                     </ul>
                                </div>
                            </div>
                            <div class="col s12 no-padding" v-if="showMetricsForm">
                                <div class="col s12 vert-offset-top-1">
                                    <form class="col s12" @submit.prevent="confirmSaveMetrics">
                                        <div class="row vert-offset-bottom-1 padding-right-half">
                                            <custom-select-component :options="list_metrics"  v-model.trim="$v.selected_metric_key.$model" title="Type to select a metric type" :searchStart="false" :class="{'is-valid': $v.selected_metric_key.$error, 'is-valid': !$v.selected_metric_key.$invalid}" >
                                            </custom-select-component>
                                            <div class="col s12 vert-offset-left-3 form-error">
                                                <span v-if="submitted && !$v.selected_metric_key.required">Metric type is required.</span>
                                            </div>
                                        </div>
                                        <div class="col s12 vert-offset-top-1">
                                            <input-component v-model.trim="$v.metric_size.$model" type="text" id="metric_size" label="Metric Total" :class="{'is-valid': submitted && $v.metric_size.$error, 'is-valid': submitted && !$v.metric_size.$invalid}"></input-component>
                                            <div class="col s12 vert-offset-left-1 form-error">
                                            <span v-if="$v.metric_size.$dirty && !$v.metric_size.required && submitted">
                                                Metric size is required.
                                            </span>

                                                <span v-if="$v.name.$dirty && !$v.metric_size.numeric">
                                                Metric total should be a number.
                                            </span>

                                            </div>
                                        </div>
                                       <!-- <div class="col s12">
                                            <div class="file-field input-field">
                                                <div class="btn yellow darken-2 black-text">
                                                    <span>File</span>
                                                    <input type="file" accept="application/pdf" @change="uploadPDF"/>
                                                </div>
                                                <div class="file-path-wrapper">
                                                    <input class="file-path validate" type="text"/>
                                                </div>
                                            </div>
                                        </div>-->
                                        <div class="col s12 right-align">
                                            <button type="submit"  class="btn-small sp-gold rounded-15">Save</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                        <div id="banking" class="padding-top-half">
                            <div class="col s12 no-padding">
                                <div class="col s9 no-padding">
                                    Banking Details
                                </div>
                                <!--<div class="col s3 no-padding font-10 right-align" v-if="hasDocuments">
                                    <a class="sp-maroon-text  clickable underline  rounded-15 text-transform-normal" @click.prevent="toggleDocsForm">
                                        <span v-if="!showDocsForm">
                                            <i class="material-icons font-12 sp-maroon-text">edit</i>Edit
                                        </span>
                                        <span v-if="showDocsForm">
                                            <i class="material-icons font-12 sp-maroon-text">cancel</i><i class="material-icons sp-maroon-text font-11">cancel</i>Cancel
                                        </span>
                                    </a>
                                </div>-->
                            </div>


                        </div>
                    </div>

                </div>

               <!-- <div class="hide-on-med-and-up">
                    <collapsible-component>
                        <li class="active">
                            <div class="collapsible-header"><i class="material-icons">arrow_drop_down</i>Products</div>
                            <div class="collapsible-body" v-if="hasProducts">
                                <div v-for="(item,i) in supplier.products.data" :key="i">
                                    <div>{{item}}</div></div>
                            </div>
                        </li>
                        <li>
                            <div class="collapsible-header"><i class="material-icons">place</i>Documents</div>
                            <div class="collapsible-body" v-if="hasDocuments"><div v-for="(item,i) in supplier.documents.data" :key="i">
                                <div>{{item.name}}</div></div></div>
                        </li>
                        <li>
                            <div class="collapsible-header"><i class="material-icons">place</i>Metrics</div>
                            <div class="collapsible-body" v-if="hasMetrics"><div v-for="(item,i) in supplier.metrics.data" :key="i">
                                <div>{{item.name}}</div></div></div>
                        </li>
                       &lt;!&ndash; <li>
                            <div class="collapsible-header"><i class="material-icons">place</i>Banking Details</div>
                            <div class="collapsible-body"><div v-for="(item,i) in supplier.funds.data" :key="i">
                                <div>{{item.name}}</div></div></div>
                        </li>
&ndash;&gt;
                    </collapsible-component>
                </div>-->


            </div>


        </div>


    </div>

</template>
<script>
    import {email, maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
    import M from 'materialize-css'
    import TabComponent from "../../components/materialize/TabComponent.vue";
    import CustomSelectComponent from "../../components/materialize/CustomSelectComponent.vue";
    import CustomMultiSelectComponent from "../../components/materialize/CustomMultiSelectComponent.vue";
    import CustomChipComponent from "../../components/materialize/CustomChipComponent.vue";
    import InputComponent from "../../components/materialize/InputComponent.vue";

    import {mapGetters, mapState} from "vuex";
    import { bus } from "../../main";
    import http from "../../http-common";



    export default{
        name:"ViewSupplier",
        components:{
            TabComponent, CustomSelectComponent,CustomMultiSelectComponent,CustomChipComponent,InputComponent
        },
        created(){
            M.AutoInit();
            let _thisObj = this;
            bus.$off('confirmAction');
            bus.$on("confirmAction", _data => {
                if (_data == "confirmSavePatronageType")
                {
                    console.log("ACTION: " + _data);
                    _thisObj.savePatronageType();
                }
                if (_data == "confirmSaveOwnershipType")
                {
                    console.log("ACTION: " + _data);
                    _thisObj.saveOwnershipType();
                }
                if (_data == "confirmSaveIndustry")
                {
                    console.log("ACTION: " + _data);
                    _thisObj.saveIndustry();
                }
                if (_data == "confirmSaveSector")
                {
                    console.log("ACTION: " + _data);
                    _thisObj.saveSector();
                }
                if (_data == "confirmSaveProduct") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveProduct();
                }
                if (_data == "confirmSaveName") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveName();
                }
                if (_data == "confirmSaveContacts") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveContacts();
                }
                if (_data == "confirmSaveLocality") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveLocality();
                }
                if (_data == "confirmSaveLogo") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveLogo();
                }
                if (_data == "confirmSaveDocument") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveDocument();
                }
                if (_data == "confirmDeleteChip") {
                    console.log("ACTION: " + _data);
                    _thisObj.deleteChip();
                }
                if (_data == "confirmDeleteDocument") {
                    console.log("ACTION: " + _data);
                    _thisObj.deleteFile();
                }
                if (_data == "confirmSaveMetric") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveMetric();
                }
                if (_data == "confirmDeleteMetric") {
                    console.log("ACTION: " + _data);
                    _thisObj.removeMetric();
                }
                if (_data == "confirmVerification") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveVerification();
                }
                if (_data == "confirmMetricVerification") {
                    console.log("ACTION: " + _data);
                    _thisObj.saveMetricVerification();
                }
                _thisObj.$store.dispatch("SET_ACTION", "");
            });

            _thisObj.getSectors();
            _thisObj.getIndustries();
            _thisObj.getPatronageTypes();
            _thisObj.getOwnershipTypes();
            _thisObj.getProductTypes();
            _thisObj.getProductTypes();
            _thisObj.getDocumentTypes();
            _thisObj.getMetrics();
            _thisObj.getCountries();
            //_thisObj.getSupplier();

            _thisObj.name=_thisObj.supplier.name;
            _thisObj.email = _thisObj.supplier.email;
            _thisObj.mobile = _thisObj.supplier.contact;
            _thisObj.postal_address = _thisObj.supplier.postal;
            _thisObj.physical_address = _thisObj.supplier.physical;
            if(_thisObj.hasSupplier)
             {

                 console.log("data is not empty")
            }

        },
        mounted(){
            this.loadListProducts();
        },
        data(){
            return {
                metric_size:null,
                selected_metric_key:null,
                item_to_delete:null,
                metric_to_delete:null,
                picture:null,
                collapsable_:false,
                showContactEdit:false,
                showLocalityForm:false,
                showLogoForm:false,
                showContactForm:false,
                showMetricsForm:false,
                showDetailsForm:false,
                showNameForm:false,
                showPatronageForm:false,
                showPatronageEdit:false,
                showOwnershipForm:false,
                showIndustriesForm:false,
                showSectorsForm:false,
                showProductsForm:false,
                showDocsForm:false,
                mobile:null,
                name:null,
                email:null,
                logo:null,
                physical_address:null,
                postal_address:null,
                selected_patronagetype_key:null,
                selected_ownershiptype_key:null,
                selected_city:null,
                selected_city_key:null,
                selected_district:null,
                selected_district_key:null,
                selected_country:null,
                selected_country_key:null,
                selected_businesstype_key: null,
                selected_producttype_key: null,
                selected_doctype_key: null,
                doc_file: null,
                selected_industry: [],
                selected_industries: [],
                selected_sector: [],
                selected_sectors: [],
                products_tags: [],
                selected_products_chips:[],
                submitted:false,
                list_products: [],
                resetProductsForm: false
            }
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(100)
            },
            mobile: {
                required,
                numeric,
                minLength: minLength(7),
                maxLength: maxLength(12)
            },
            email: {
                email,
                required,
                maxLength: maxLength(100)
            },
            logo: {

                required,

            },
            doc_file: {

                required,

            },
            postal_address: {
                required,
                minLength:minLength(3),
                maxLength: maxLength(200)
            },
            physical_address: {
                required,
                maxLength: maxLength(200),
                minLength: minLength(3)
            },
            selected_city_key:{
                required
            },
            selected_country_key:{
                required
            },
            selected_district_key:{
                required
            },
            selected_businesstype_key: {
                required,
            },
            selected_ownershiptype_key: {
                required,
            },
            selected_patronagetype_key: {
                required,
            },
            selected_metric_key: {
                required,
            },
            metric_size: {
                required,
                numeric
            },
            selected_producttype_key: {
                required,
            },
            selected_industry: {
                minLength:minLength(1)

            },
            list_products: {
                minLength:minLength(1)

            },
            selected_sector: {
                minLength:minLength(1)
            },
            selected_doctype_key:{
                required
            }
        },
        methods:{

            toggleLogoForm:function () {
                this.showLogoForm = !this.showLogoForm;
            },
            toggleDetailsForm:function () {
                this.showDetailsForm = !this.showDetailsForm;
            },
            toggleContactForm:function () {
                this.showContactForm = !this.showContactForm;
            },
            toggleMetricsForm:function () {
                this.showMetricsForm = !this.showMetricsForm;
            },
            toggleNameForm:function () {
                this.showNameForm = !this.showNameForm;
            },
            toggleLocalityForm:function () {
                this.showLocalityForm = !this.showLocalityForm;
            },
            togglePatronageForm:function () {
                this.showPatronageForm = !this.showPatronageForm;
            },
            toggleOwnershipForm:function () {
                this.showOwnershipForm = !this.showOwnershipForm;
            },
            toggleIndustriesForm:function () {
                this.showIndustriesForm = !this.showIndustriesForm;
            },
            toggleSectorsForm:function () {
                this.showSectorsForm = !this.showSectorsForm;
            },
            toggleProductsForm:function () {
                this.showProductsForm = !this.showProductsForm;
                if(this.showProductsForm)
                {
                    this.resetProductsForm = true;
                }
            },
            toggleDocsForm:function () {
                this.showDocsForm = !this.showDocsForm;
            },

            getCountries: function() {
                this.$store.dispatch('GET_COUNTRIES_LIST');
            },
            getSupplier: function() {
                this.$store.dispatch('GET_SUPPLIER');
            },
            getBusinessTypes: function() {
                this.$store.dispatch('GET_BUSINESSTYPES_LIST');
            },
            getPatronageTypes: function() {
                this.$store.dispatch('GET_PATRONAGETYPES_LIST');
            },
            getOwnershipTypes: function() {
                this.$store.dispatch('GET_OWNERSHIPTYPES_LIST');
            },
            getProductTypes: function() {
                this.$store.dispatch('GET_PRODUCTTYPES_LIST');
            },
            getDocumentTypes: function() {
                this.$store.dispatch('GET_DOCUMENTTYPES_LIST');
            },
            getMetrics: function() {
                this.$store.dispatch('GET_METRICS_LIST');
            },
            getIndustries: function() {
                this.$store.dispatch('GET_INDUSTRIES_LIST');
            },
            getSectors: function() {
                this.$store.dispatch('GET_SECTORS_LIST');
            },
            getCities:function (_district) {
                let _formdata = {district:_district};
                this.$store.dispatch('GET_CITIES_LIST',_formdata);

            },
            getDistricts:function (_country) {

                let _formdata = {country:_country};
                this.$store.dispatch('GET_DISTRICTS_LIST',_formdata);
            },
            getWards:function (_city) {
                let _formdata = {city:_city};
                this.$store.dispatch('GET_COUNTRIES_LIST',_formdata);
            },

            addProduct: function()
            {
                if(!this.hasSelectedProductTypeKey || this.selected_products_chips.length <= 0)
                    return;

                for(let i=0;i<this.selected_products_chips.length;i++)
                {
                    let _obj = {
                        type: this.selected_producttype_key,
                        name: this.selected_products_chips[i]
                    };
                    let _arrFound = [];
                    if(this.list_products.length > 0)
                    {
                        _arrFound = this.list_products.filter(function(_item)
                        {
                            return (_item.name === _obj.name && _item.type === _obj.type);
                        });
                    }

                    if(typeof _arrFound === "undefined" || _arrFound === null || _arrFound.length <= 0)
                    {
                        this.list_products.push(_obj);
                    }
                    this.resetProductsForm = false;
                    /*this.selected_producttype_key = null;
                    this.selected_products_chips = [];*/
                }
            },
            removeProduct(_item)
            {
                if(typeof _item !== "undefined" && _item !== null)
                    this.list_products.splice(this.list_products.indexOf(_item));
            },


            confirmSaveName:function () {
                let _objThis = this;
                //_objThis.submitted = true;
                _objThis.$v.name.$touch();
                if (_objThis.$v.name.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmSaveName");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveName");
            },
            saveName:function () {

                let _objThis = this;

                let _formdata = {
                    supplier:_objThis.supplier.key,
                    name:_objThis.name,
                };

                this.$store.dispatch('SAVE_NAME',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveLocality:function () {
                let _objThis = this;
                //_objThis.submitted = true;
                _objThis.$v.selected_country_key.$touch();
                _objThis.$v.selected_district_key.$touch();
                _objThis.$v.selected_city_key.$touch();
                if (_objThis.$v.selected_country_key.$invalid || _objThis.$v.selected_district_key.$invalid || _objThis.$v.selected_city_key.$invalid ) {
                    return;
                }
                console.log("Event Triggered: confirmSaveLocality");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveLocality");
            },
            saveLocality:function () {

                let _objThis = this;

                let _formdata = {
                    supplier:_objThis.supplier.key,
                    country:_objThis.selected_country_key,
                    district:_objThis.selected_district_key,
                    city:_objThis.selected_city_key,
                };

                this.$store.dispatch('SAVE_LOCALITY',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveContacts:function () {
                let _objThis = this;
                //_objThis.submitted = true;
                _objThis.$v.mobile.$touch();
                _objThis.$v.postal_address.$touch();
                _objThis.$v.physical_address.$touch();
                if (_objThis.$v.mobile.$invalid || _objThis.$v.postal_address.$invalid || _objThis.$v.physical_address.$invalid ) {
                    return;
                }
                console.log("Event Triggered: confirmSaveContacts");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveContacts");
            },
            saveContacts:function () {

                let _objThis = this;

                let _formdata = {
                    supplier:_objThis.supplier.key,
                    mobile:_objThis.mobile,
                    postal_address:_objThis.postal_address,
                    physical_address:_objThis.physical_address,
                };

                this.$store.dispatch('SAVE_CONTACTS',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSavePatronageType:function () {
                let _objThis = this;
                //_objThis.submitted = true;
                _objThis.$v.selected_patronagetype_key.$touch();
                if (_objThis.$v.selected_patronagetype_key.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmSavePatronageType");
                _objThis.$store.dispatch("SET_ACTION", "confirmSavePatronageType");
            },
            savePatronageType:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    entity:_objThis.selected_patronagetype_key,
                };

                this.$store.dispatch('SAVE_PATRONAGETYPE',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveOwnershipType:function () {
                let _objThis = this;
                //_objThis.submitted = true;
                _objThis.$v.selected_ownershiptype_key.$touch();
                if (_objThis.$v.selected_ownershiptype_key.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmSaveOwnershipType");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveOwnershipType");
            },
            saveOwnershipType:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    ownership:_objThis.selected_ownershiptype_key,
                };

                this.$store.dispatch('SAVE_OWNERSHIPTYPE',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveIndustry:function () {
                let _objThis = this;
                _objThis.submitted = true;
                _objThis.$v.$touch();
                if (_objThis.$v.selected_industry.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmSaveIndustry");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveIndustry");
            },
            saveIndustry:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    industries:_objThis.selected_industries,
                };

                this.$store.dispatch('SAVE_INDUSTRY',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveProduct:function () {
                let _objThis = this;
                _objThis.submitted = true;
                if (_objThis.list_products.length <= 0) {
                    return;
                }
                console.log("Event Triggered: confirmSaveProduct");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveProduct");
            },
            saveProduct:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    products:_objThis.list_products,
                };

                this.$store.dispatch('SAVE_PRODUCT',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveSector:function () {
                let _objThis = this;
                _objThis.submitted = true;
                _objThis.$v.selected_sector.$touch();
                if (_objThis.$v.selected_sector.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmSaveSector");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveSector");
            },
            saveSector:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    sectors:_objThis.selected_sectors,
                };

                this.$store.dispatch('SAVE_SECTOR',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveDocument:function () {
                let _objThis = this;
                _objThis.submitted = true;
                _objThis.$v.doc_file.$touch();
                if (_objThis.$v.doc_file.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmSaveDocument");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveDocument");
            },
            saveDocument:function () {

                let _objThis = this;
                let formdata = new FormData();

                formdata.append("file", this.doc_file);
                formdata.append("item",_objThis.supplier.key);
                formdata.append("document",_objThis.selected_doctype_key);


                this.$store.dispatch('SAVE_DOCUMENT',formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveLogo:function () {
                let _objThis = this;
                _objThis.submitted = true;
                _objThis.$v.logo.$touch();
                if (_objThis.$v.logo.$invalid) {
                    return;
                }
                console.log("Event Triggered: confirmSaveLogo");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveLogo");
            },
            saveLogo:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    picture:_objThis.logo,
                    role:_objThis.userRole.key
                };

                this.$store.dispatch('SAVE_LOGO',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })


            },

            confirmSaveMetrics:function () {
                let _objThis = this;
                _objThis.submitted = true;
                _objThis.$v.selected_metric_key.$touch();
                _objThis.$v.metric_size.$touch();
                if (_objThis.$v.selected_metric_key.$invalid || _objThis.$v.metric_size.$invalid  ) {
                    return;
                }
                console.log("Event Triggered: confirmSaveMetricr");
                _objThis.$store.dispatch("SET_ACTION", "confirmSaveMetric");
            },
            saveMetric:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    metric:_objThis.selected_metric_key,
                    total: _objThis.metric_size
                };

                _objThis.$store.dispatch('SAVE_METRIC',_formdata).then(() => {
                    window.console.log("Reload Page");
                    _objThis.submitted = false;

                    _objThis.selected_metric_key = null;
                    _objThis.metric_size = null;
                    _objThis.$forceUpdate();
                })


            },

            
            
            confirmRemoveMetric:function (_metric) {
                let _objThis = this;

                _objThis.metric_to_delete = _metric;
                console.log("Event Triggered: confirmDeleteMetricr");
                _objThis.$store.dispatch("SET_ACTION", "confirmDeleteMetric");
            },
            removeMetric:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    metric:_objThis.metric_to_delete,
                };
                console.log("FormData: "+_formdata)

                /*this.$store.dispatch('DELETE_METRIC',_formdata).then(() => {
                    window.console.log("Reload Page");
                    this.submitted = false;
                    this.$forceUpdate();
                })*/


            },


            confirmMetricVerification:function (item) {
                let _objThis = this;

                this.item_to_delete = item.key;
                console.log("Event Triggered: confirmMetricVerification");
                _objThis.$store.dispatch("SET_ACTION", "confirmMetricVerification");
            },
            saveMetricVerification:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    metric:_objThis.item_to_delete,
                    role:_objThis.userRole.key
                };

                _objThis.$store.dispatch('SUPPLIER_VERIFY_METRIC',_formdata).then(() => {
                    window.console.log("Reload Page");
                    _objThis.$forceUpdate();
                })


            },
            
            confirmVerification:function (item) {
                let _objThis = this;

                this.item_to_delete = item.key;
                console.log("Event Triggered: confirmVerification");
                _objThis.$store.dispatch("SET_ACTION", "confirmVerification");
            },
            saveVerification:function () {

                let _objThis = this;

                let _formdata = {
                    item:_objThis.supplier.key,
                    document:_objThis.item_to_delete,
                    role:_objThis.userRole.key
                };

                _objThis.$store.dispatch('SUPPLIER_VERIFY_DOCUMENT',_formdata).then(() => {
                    window.console.log("Reload Page");
                    _objThis.$forceUpdate();
                })


            },


            loadListProducts()
            {
                this.list_products = [];
                if(this.hasSupplier && this.hasProducts)
                for(let i=0;i<this.supplier.products.data.length;i++)
                {
                    this.list_products.push({
                        type: this.supplier.products.data[i].type.data.key,
                        name: this.supplier.products.data[i].name
                    });
                }
            },

            uploadImage(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.logo = e.target.result;
                    this.picture = e.target.result;
                    console.log(this.picture);
                };
            },

            uploadPDF(e) {
                let $this = this;
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                $this.doc_file = files[0];
            },

            openFile:function (_doc) {
                this.$store.dispatch("START_PROCESSING");
                return new Promise(resolve => {
                    http({
                        url: "/suppliers/download/document",
                        data: {
                            item: this.supplier.key,
                            document:_doc.key
                        },
                        responseType: "blob",
                        method: "POST",
                        timeout: 30000
                    })
                        .then(resp => {
                            this.$store.dispatch("STOP_PROCESSING");

                            console.log("Data: "+JSON.stringify(resp.data));
                            const blob = new Blob([resp.data], {
                                type: "application/pdf"
                            });
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.target = "_blank";
                            link.setAttribute("download", _doc.name+".pdf");
                            document.body.appendChild(link);
                            link.click();
                            //URL.revokeObjectURL(link.href);
                            //console.log("RESP-"+JSON.stringify(resp.headers['content-type: ']));

                            resolve(resp);
                        })
                        .catch(response => {
                            console.log("Error: " + JSON.stringify(response.data));
                            this.$store.dispatch("STOP_PROCESSING");
                            this.$store.dispatch("GET_MESSAGES", response.data.messages);
                        });
                });
            },

            confirmDeleteFile:function(_doc){
                let _objThis = this;

                this.item_to_delete = _doc;
                console.log("Event Triggered: confirmDeleteDocument");
                _objThis.$store.dispatch("SET_ACTION", "confirmDeleteDocument");
            },
            deleteFile:function () {

            },

            getSelectedIndustries:function(selection_arr) {
                window.console.log("Selected Industries: "+JSON.stringify(selection_arr));

                this.selected_industries = [];
                //this.filter_departments_names = [];

                this.selected_industries = selection_arr.keys;
                //this.filter_departments_names= selection_arr.names;
            },
            getSelectedSectors:function(selection_arr) {
                window.console.log("Selected Sectors: "+JSON.stringify(selection_arr));

                this.selected_sectors = [];
                //this.filter_departments_names = [];

                this.selected_sectors = selection_arr.keys;
                //this.filter_departments_names= selection_arr.names;
            },

            chipData: function (data) {

                this.selected_products_chips = [];
                console.log("Data: " + JSON.stringify(data));

                ///this.selectedChips = data;

                for (let i = 0; i < data.length; i++) {

                    this.selected_products_chips.push(data[i].tag);

                }
                console.log("Selected Chips: " + JSON.stringify(this.selected_products_chips));

                //return data;
            },
            deleteChipData: function (data) {

                console.log("delete chip?");
                console.log("Event Triggered: confirmDeleteChip");
                this.selected_products_chips = [];
                console.log("Data: " + JSON.stringify(data));

                ///this.selectedChips = data;

                for (let i = 0; i < data.length; i++) {

                    this.selected_products_chips.push(data[i].tag);

                }
                console.log("Selected Chips: " + JSON.stringify(this.selected_products_chips));
                this.$store.dispatch("SET_ACTION", "confirmDeleteChip");

                //return data;
            },

            productsDelete:function (data) {
                console.log("delete chip?");
                console.log("Event Triggered: confirmDeleteChip");
                this.selected_products_chips = [];
                console.log("Data: " + JSON.stringify(data));
                for (let i = 0; i < data.length; i++) {

                    this.selected_products_chips.push(data[i].tag);

                }

                this.$store.dispatch("SET_ACTION", "confirmDeleteChip");
            },
            deleteChip:function(){

            },
            validateSize:function(value)
            {
                return value !== null;
            },

            hasSelectedIndustry:function () {
                return this.selected_industries.length > 0;
            }

        },
        computed:{
            ...mapState({
                list_countries: state => state.organisations.list_countries,
                list_districts:state => state.districts.list_districts,
                list_cities:state => state.cities.list_cities,
                list_businesstypes:state => state.organisations.list_businesstypes,
                list_patronagetypes:state => state.organisations.list_patronagetypes,
                list_ownershiptypes:state => state.organisations.list_ownershiptypes,
                list_producttypes:state => state.organisations.list_producttypes,
                list_industries:state => state.organisations.list_industries,
                list_sectors:state => state.organisations.list_sectors,
                list_doctypes:state => state.organisations.list_documenttypes,
                list_metrics:state => state.organisations.list_metrics,
                supplier:state => state.organisations.supplier,
                user:state => state.user,
            }),

            ...mapGetters({
                isSystemAdmin:"isSystemAdmin",
                isAdmin: "isAdmin",
                isStandard: "isStandard",
                isBusinessUser:"isBusinessUser",
            }),

            hasUser:function () {
                return typeof this.user !== "undefined" && this.user !== null;
            },
            hasRoles:function () {
                return ((this.hasUser && typeof this.user.roles !== "undefined" && this.user.roles !== null) && (typeof this.user.roles.data !== "undefined" && this.user.roles.data !== null  && this.user.roles.data.length > 0));
            },

            userRole:function(){
                if(!this.hasRoles)
                {
                    return null;
                }

                return this.user.roles.data[0];

            },
            isPicture: function() {
                return this.picture !== null;
            },
            hasEmail:function () {
                return typeof this.supplier.email !== "undefined" && this.supplier.email !== null
            },
            hasSelectedProductTypeKey:function () {
                return (typeof this.selected_producttype_key !== "undefined" && this.selected_producttype_key !== null && this.selected_producttype_key.length > 0);
            },
            hasSupplier:function () {
                return (typeof this.supplier !== "undefined" && this.supplier !== null);
            },
            citiesExists: function() {
                return typeof this.list_cities !== "undefined" && this.list_cities !== null && this.list_cities.length > 0;
            },

            countriesExists:function () {
                return typeof this.list_countries !== "undefined" && this.list_countries !== null && this.list_countries.length > 0;
            },

            districtsExists:function () {
                return typeof this.list_districts !== "undefined" && this.list_districts !== null && this.list_districts.length > 0;
            },
            hasEntities:function () {
                return this.hasSupplier && typeof this.supplier.entity !== "undefined" && this.supplier.entity !== null && typeof this.supplier.entity.data !== "undefined" && this.supplier.entity.data !== null;
            },
            hasOwnership:function () {
                return this.hasSupplier && typeof this.supplier.ownership !== "undefined" && this.supplier.ownership !== null && typeof this.supplier.ownership.data !== "undefined" && this.supplier.ownership.data !== null;
            },
            totalProducts: function()
            {
                if(!this.hasProducts)
                    return 0;
                return this.supplier.products.data.length;
            },
            hasIndustries:function () {
                return  this.hasSupplier && typeof this.supplier.industries !== "undefined" && this.supplier.industries !== null && typeof this.supplier.industries.data !== "undefined" && this.supplier.industries.data !== null && this.supplier.industries.data.length > 0;
            },
            hasSectors:function () {
                return this.hasSupplier && typeof this.supplier.sectors !== "undefined" && this.supplier.sectors !== null && typeof this.supplier.sectors.data !== "undefined" && this.supplier.sectors.data !== null && this.supplier.sectors.data.length > 0;
            },
            hasProducts:function () {
                return this.hasSupplier && typeof this.supplier.products !== "undefined" && this.supplier.products !== null && typeof this.supplier.products.data !== "undefined" && this.supplier.products.data !== null && this.supplier.products.data.length > 0;
            },

            hasMetrics:function () {
                return this.hasSupplier && typeof this.supplier.metrics !== "undefined" && this.supplier.metrics !== null && typeof this.supplier.metrics.data !== "undefined" && this.supplier.metrics.data !== null && this.supplier.metrics.data.length > 0;
            },


            hasDocuments:function () {
                return this.hasSupplier && typeof this.supplier.documents !== "undefined" && this.supplier.documents !== null && typeof this.supplier.documents.data !== "undefined" && this.supplier.documents.data !== null&& this.supplier.documents.data.length > 0;
            }
        },
        watch:{
            "selected_country_key": function (_value) {
                //this.list_districts =[];

                window.console.log("Selected Country: "+_value);
                this.selected_country = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_countries.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        this.getDistricts(_value);
                        console.log("Found Country: "+_arrFound[0].id);
                        this.selected_country = _arrFound[0];

                    }
                }
            },
            "selected_district_key": function (_value) {
                //this.list_cities =[];

                this.selected_district = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_districts.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        this.getCities(_value);
                        console.log("Found District: "+_arrFound[0].id);
                        this.selected_district = _arrFound[0];

                    }
                }
            },
            "selected_city_key": function (_value) {
                //this.list_cities =[];

                this.selected_city = null;
                if(typeof _value !== "undefined" && _value !== null) {

                    let _arrFound = this.list_cities.filter(function(item)
                    {
                        return (item.key === _value);
                    });
                    if(_arrFound.length>0)
                    {
                        //this.getWards(_value);
                        console.log("Found City: "+_arrFound[0].id);
                        this.selected_city = _arrFound[0];

                    }
                }
            },
            "$route.params.id":function(value){
                console.log("params.."+this.$route.params.id);

                if(value == 2)
                {
                    this.collapsable_ = true;
                }
            },



        }

    }
</script>